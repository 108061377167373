import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard2 = ({ id, link, title, createdAt }) => {
  const date = createdAt ? moment(createdAt).format("DD MMMM YYYY") : null
  return (
    <div className='article-mini'>
      <time className='d-block fw-bold'>{date}</time>
      <h5><Link to={`/blog/${id}`}>{title}</Link></h5>
    </div>
  );
};

export default BlogCard2;