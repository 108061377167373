import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Chat from "../../components/chat/Chat";
import Loader from "../../components/utils/Loader";
import socket from "../../config/socket";
import {
  createMessage,
  createMessageGeneral,
  getMessages,
  getMessagesGeneral
} from "../../services/message";
import { createTask } from '../../services/task';
import { NotificationManager } from "react-notifications";


const MessagesDialogue = () => {
  const { dialogId } = useParams();
  const { state } = useLocation();
  const [sum, setSum] = useState(true);
  const timer = useRef(0);
  const userId = useSelector((state) => state.auth?.user?.id);
  const navigate = useNavigate();
  const [t, setT] = useState(1);
  const { control, reset, setValue } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      id: state?.dialogId ?? dialogId,
    },
  });

  const data = useWatch({ control });

  const [print, setPrint] = useState(false);


  const [messages, setMessages] = useState({
    loading: true,
    items: [],
  });



  useEffect(() => {
    (state?.dialogId || (dialogId && dialogId != "newMessage")) &&
      setValue("id", state?.dialogId ?? dialogId);
  }, [state?.dialogId, dialogId]);

  useEffect(() => {
    if (data?.id && sum) {
      // viewMessages(data);
      if (data?.id == "general") {
        getMessagesGeneral()
          .then((res) =>
            setMessages((prev) => ({
              ...prev,
              loading: false,
              items: res.messages.items,
            }))
          )
          .catch(() => setMessages((prev) => ({ ...prev, loading: false })));
      } else if (dialogId != "newMessage") {
        getMessages(data)
          .then((res) => {
            setMessages((prev) => ({
              ...prev,
              loading: false,
              items: res.messages.items,
              dialog: res.dialog
            }));
          })
          .catch(() => setMessages((prev) => ({ ...prev, loading: false })));
      }
    }
    setSum(true)
    if (data?.id == "newMessage") {
      setSum(false)
      reset({
        toId: state.id,
        fromId: userId,
      })
      getMessages({
        toId: state.id,
        fromId: userId
      })
        .then((res) => {
          navigate("/account/messages/" + res.dialog.id)
          setMessages((prev) => ({
            ...prev,
            loading: false,
            items: res.messages.items,
            dialog: res.dialog
          }));
          setT(2)

        })
        .catch(() => setMessages(() => ({
          loading: false,
          dialog: { to: state },
        })));


    }
  }, [data?.id]);

  useEffect(() => {
    if (data?.id && dialogId != "newMessage") {
      socket.emit("createRoom", "message/" + data.id);

      socket.on("message", (data) => {
        if (data) {
          setPrint(false);
          setMessages((prev) => ({
            ...prev,
            loading: false,
            items: [
              data,
              ...prev.items,
            ],
          }));
        }
      });
      socket.on("report", (data) => {
        if (data) {
          setMessages((prev) => ({
            ...prev,
            loading: false,
            items: [
              data,
              ...prev.items,
            ],
          }));
        }
      });
      return () => {
        socket.emit("removeRoom", "message/" + data.id);
        socket.off("message");
        socket.off("report");
        // socket.off("message/print/" + data.id);
      };
    }
  }, [data?.id]);

  useEffect(() => {
    if (timer.current === 0 && data?.text?.length > 0) {
      timer.current = 1;
      setPrint(true);
      socket.emit("message/print", { adminId: data.id });
      setTimeout(() => {
        timer.current = 0;
        setPrint(false);
      }, 5000);
      return () => clearTimeout(timer.current);
    }
  }, [data?.text]);

  const onNewMessage = useCallback(
    (text) => {
      if (data?.id === "general" || dialogId === "general") {
        createMessageGeneral({ ...data, text });
      } else {
        createMessage({ ...data, text })
          .then((res) => {
            if (!data?.id) {
              getMessages(data)
                .then((res) => {
                  setMessages((prev) => ({
                    ...prev,
                    loading: false,
                    items: res.messages.items,
                    dialogId: res.dialog.id,
                    dialog: res.dialog,
                  }));
                  setValue("id", res.dialog.id);
                  setT(2)
                  navigate("/account/messages/" + res.dialog.id)
                })
                .catch(() => setMessages((prev) => ({ ...prev, loading: false })));
            }
          })
      }

      reset({ id: data.id ?? dialogId });
    },
    [data, state, dialogId]
  );
  const user =
    userId == messages?.dialog?.to?.id
      ? messages?.dialog?.from
      : messages?.dialog?.to;

  const onTask = useCallback(() => {
    createTask({ type: "report", userId: user.id })
      .then(() => {
        NotificationManager.success("Жалоба отправлена");

      })
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при отправке"
        );
      });
  }, [user]);
  if (messages.loading) {
    return <Loader full />;
  }

  return (
    <section className='sec-messages'>
      <Meta title="Сообщения" />

      <Chat
        t={t}
        print={print}
        onTask={(e) => onTask(e)}
        account="true"
        general={data.id}
        user={user}
        messages={messages}
        emptyText="Нет сообщений"
        onSubmit={(e) => onNewMessage(e)}
        onChange={(e) => setValue("text", e)}
        data={data}
        setImage={(e) => setValue("media", Array.from(e))}
      />

    </section>
  );
};

export default MessagesDialogue;