import React from "react";
import { Link } from "react-router-dom";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { getImageURL } from "../helpers/all";

const MainSlider = ({ sales }) => {
  return (
    <section className="main-slider">
      {<Swiper
        className="mainslides"
        modules={[Pagination, Autoplay]}
        loop={true}
        // effect={"fade"}
        spaceBetween={0}
        slidesPerView={1}
        pagination={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
      >
        {sales?.items?.map((e) =>
          <SwiperSlide key={e.id} className="slide">
            <Link to={e?.link} target='_blank'>
              <img
                src={
                  getImageURL({
                    path: e.media,
                    type: "banner",
                    size: "full",
                  })
                }
                alt=""
                className="slide-img"
              />
              <div className="slide-caption container">
                <h2>{e.title}</h2>
                <p>{e.desc}</p>
              </div>
            </Link>
          </SwiperSlide>
        )}
      </Swiper>
      }

    </section>
  );
};

export default MainSlider;
