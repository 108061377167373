import React from 'react';
import {Link} from 'react-router-dom';
import { BsArrowReturnLeft } from "react-icons/bs";

const ReturnTitle = (props) => {
  return (
    <div className="return-title">
      <Link to={props.link} className='me-3'>
        <BsArrowReturnLeft className='fs-13'/>
      </Link>
      <h4 className='mb-0'>{props.title}</h4>
    </div>
  );
};

export default ReturnTitle;