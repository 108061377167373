import { apiRoutes } from '../config/api'
import { $api } from './index'

const getInstructions = async () => {
    const response = await $api.get(apiRoutes.INSTRUCTIONS)
    return response?.data
}
const getInstruction = async (data) => {
    const response = await $api.get(apiRoutes.INSTRUCTION, { params: data })
    return response?.data
}

export { getInstructions, getInstruction }