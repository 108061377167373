import React, { useCallback } from 'react';
import { Container } from "react-bootstrap";
import { useForm, useWatch } from 'react-hook-form';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import LogoDark from "../assets/imgs/logo-bml-dark.svg";
import { createTaskAny } from '../services/task';
import { SlSocialVkontakte } from 'react-icons/sl';
import { FaTelegram } from 'react-icons/fa';
import Meta from '../components/Meta';

const Contacts = () => {
    const userId = useSelector(state => state.auth?.user?.id);
    const {
        control,
        register,
        formState: { errors, isValid },
        handleSubmit,
        setValue,
    } = useForm({
        mode: "onChange",
        reValidateMode: "onSubmit",
        defaultValues: userId,
    });
    const data = useWatch({ control });
    const onTask = useCallback(() => {
        createTaskAny({ type: "task", comment: data.email + ", " + data.name + ", " + data.comment, title: "Обратаная связь" })
            .then(() => {
                NotificationManager.success("Форма отправлена");

            })
            .catch((err) => {
                NotificationManager.error(
                    err?.response?.data?.error ?? "Ошибка при отправке"
                );
            });
    }, [data]);
    return (
        <main>
            <Meta title="Бизнес My Life (БМЛ) – Контакты сайта бесплатных объявлений" description="Обратная связь сайта “Бизнес моя жизнь”, контакты инверторов России, контакты бизнес партеров, контакты лучших франшиз. БМЛ это Бизнес идеи, Стартапы, свой бизнес." />
            <Container>
                <section className='py-4 py-sm-5 mb-4 mb-sm-5'>
                    <h1 className="h2">Контакты</h1>
                    <img src={LogoDark} alt="bml" className='img-fluid mb-4' />

                    <div className='extra-font'>
                        <h4 className='fw-bold mb-2'>Прием звонков: </h4>
                        <p className='fs-11'>Пн.-Пт.: 9:00 - 19:00 (по Москве)</p>

                        <h4 className='fw-bold mb-2 mt-4'>Реквизиты: </h4>
                        <p className='fs-11 mb-1'>ИП. Найденов Р.А.</p>
                        <p className='fs-11 mb-1'>ОГРНИП 313502929500012</p>
                        <p className='fs-11 mb-3'>ИНН 890604287041</p>

                        <SlSocialVkontakte className='ms-4 me-5 fs-18' />
                        <FaTelegram className='fs-18' />

                        <p className='fs-11 mt-4'>Если у вас остались вопросы, напишите нам на <a className='link' href="mailto:biznessmylife@mail.ru">biznessmylife@mail.ru</a></p>
                    </div>
                </section>

                <section className='mb-6'>
                    <h2>Форма обратной связи</h2>
                    <form action="fs-11 extra-font">
                        <p className='mb-1'>Ваше имя</p>
                        <input type="text" placeholder='Ваше имя' className='mb-3' onChange={e => setValue("name", e.target.value)} />
                        <p className='mb-1'>Ваша почта</p>
                        <input type="mail" placeholder='Ваше почта' className='mb-3' onChange={e => setValue("email", e.target.value)} />
                        <p className='mb-1'>Ваш вопрос</p>
                        <textarea rows="4" placeholder='Ваш вопрос' className='mb-3' onChange={e => setValue("comment", e.target.value)}></textarea>
                        <label className='d-flex mb-3'>
                            <input
                                type="checkbox"
                                {...register("accept", {
                                    required:
                                        "Примите условия пользовательского соглашения",
                                })}
                            />
                            <span className='flex-1 fs-08 ms-2'>Я соглашаюсь с правилами сайта и даю согласие на обработку персональных данных.</span>
                        </label>
                        <button type='submit' className='btn-1' disabled={!isValid} onClick={handleSubmit(onTask)}>Отправить</button>
                    </form>
                </section>
            </Container>
        </main>
    )
}

export default Contacts