import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getImageURL } from '../helpers/all';

const PartnerRequest = ({ to, from, onSub, incoming, item }) => {
  const userId = useSelector(state => state.auth?.user?.id);
  let user;
  // Вычисление 'user' с учетом 'incoming', 'to', и 'from'
  if (incoming === 'all') {
    user = item;
  } else if (incoming === true) {
    user = to;
  } else {
    user = from;
  }

  return (
    <div className="partnerRequest">
      <Link to={`/user/${user?.id}`}>
        <img
          src={getImageURL({
            path: user?.media,
            size: "mini",
            type: "user",
          })}
          alt="userphoto"
          className='partnerRequest-img'
        />
      </Link>

      <div className="flex-1">
        <Link to={`/user/${user?.id}`}>
          <h5 className='color-1'>{user?.firstName}</h5>
          {/* <p className="fs-08">Компания/деятельность</p> */}
        </Link>
      </div>
      <div className='partnerRequest-btns'>
        <Link to="/account/messages/newMessage" state={user} className='btn-primary'>Написать сообщение</Link>
        {/* Условный рендеринг кнопок */}
        {incoming === true ?
          <button type='button' className='btn-gray' onClick={() => onSub(user?.id)}>Отписаться</button>
          : null
        }
        {incoming === 'all' &&
          (item?.subscriptionStatus ?
            <button type='button' className='btn-gray' onClick={() => onSub(user?.id)}>Отписаться</button>
            : <button type='button' className='btn-gray' onClick={() => onSub(user?.id)}>Подписаться</button>
          )
        }
      </div>
    </div>
  );
};

export default PartnerRequest;
