import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogCard from './BlogCard';
import BlogCard2 from './BlogCard2';
import NavPagination from './NavPagination';
import { getArticles } from "../services/article";
import Loader from './utils/Loader';
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

const BlogSection = ({ articles }) => {
  if (articles.loading) {
    return <Loader />;
  }

  return (
    <section className='sec-blog mb-6'>
      <h2>Новости и статьи</h2>

      <Row>
        <Col lg={4} xl={3} className="d-none d-lg-block">
          <ul className="list-unstyled pt-3">
            {articles?.items?.length > 0 && articles.items.map((item) => (
              <li key={item?.id}>
                <BlogCard2 key={item?.id} {...item} />
              </li>
            ))}
          </ul>
          <Link to="/blog" className="color-1 mt-4">
            <span className="text-decoration-underline fw-medium">Все новости</span>
          </Link>
        </Col>
        <Col xs={12} lg={8} xl={9}>
          <Swiper
            className="py-3"
            modules={[Autoplay]}
            loop={true}
            spaceBetween={15}
            slidesPerView={1}
            pagination={false}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              576: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 15
              },
              992: {
                slidesPerView: 2,
                spaceBetween: 20
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 20
              }
            }}
          >
            {articles?.items?.length > 0 && articles.items.map((item, index) => (
              <SwiperSlide key={item.id || index}>
                <BlogCard key={item?.id} {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Col>
      </Row>
    </section>
  );
};

export default BlogSection;