import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom';
import Loader from '../components/utils/Loader';
import { getDocument } from '../services/document';

const InfoBlog = () => {
    const { id } = useParams();
    const [document, setDocument] = useState({
        loading: true,
        items: [],
    });
    useEffect(() => {
        getDocument({ id })
            .then(
                (res) =>
                    res &&
                    setDocument((prev) => ({
                        ...prev,
                        loading: false,
                        ...res,
                    }))
            )
            .catch(() => setDocument((prev) => ({ ...prev, loading: false })));


    }, [id]);
    if (document.loading) {
        return <Loader full />;
    }
    return (
        <main>
            <Container>
                <section className='py-4 py-sm-5'>
                    <h1 className="h2">{document.document.title}</h1>

                    <div className='extra-font fs-11' dangerouslySetInnerHTML={{ __html: document.document.content }} ></div>
                </section>


            </Container>
        </main >
    )
}

export default InfoBlog