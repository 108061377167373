import React, { useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap";
import ReturnTitle from "../../components/utils/ReturnTitle";
import BlogCard from '../../components/BlogCard';
import PhotoPlacement from "../../assets/imgs/photo-placement.jpg";
import Loader from '../../components/utils/Loader';
import { getInstructions } from '../../services/instructions';
import BlogInfo from '../../components/BlogInfo';
import BlogPartner from '../../components/BlogPartner';

const Info = () => {
  const [instructions, setInstructions] = useState({
    loading: true,
  });
  useEffect(() => {
    getInstructions()
      .then(
        (res) =>
          res &&
          setInstructions((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .catch(() => setInstructions((prev) => ({ ...prev, loading: false })));


  }, []);
  if (instructions.loading) {
    return <Loader full />;
  }
  return (
    <section>
      <ReturnTitle link="/account" title="Полезная информация" />
      {instructions?.instruction.length > 0 &&
        <>
          <h4>Как загрузить объявление и пользоваться сайтом</h4>
          <Row xs={1} sm={2} md={3} className='g-4'>
            {instructions?.instruction.map(item =>
              <Col>
                <BlogInfo {...item} />
              </Col>
            )}
          </Row>
        </>
      }
      {instructions?.partner.length > 0 &&
        <>
          <hr />
          <h4>Партнёры</h4>
          <Row xs={1} sm={2} md={3} className='g-4'>
            {instructions?.partner.map(item =>
              <Col>
                <BlogPartner {...item} />
              </Col>
            )}
          </Row>
        </>
      }
    </section>
  )
}

export default Info