import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import LogoDark from "../assets/imgs/logo-bml-dark.svg";
import { Link } from 'react-router-dom';
import { getDocuments } from '../services/document';
import Loader from '../components/utils/Loader';
import Meta from '../components/Meta';

const Info = () => {
    const [documents, setDocuments] = useState({
        loading: true,
        items: [],
    });
    useEffect(() => {
        getDocuments()
            .then(
                (res) =>
                    res &&
                    setDocuments((prev) => ({
                        ...prev,
                        loading: false,
                        ...res,
                    }))
            )
            .catch(() => setDocuments((prev) => ({ ...prev, loading: false })));


    }, []);
    if (documents.loading) {
        return <Loader full />;
    }
    return (
        <main>
            <Meta title="Бизнес моя жизнь – информации по сайту" description="У нас лучшие инвесторы и бизнес партнеры. Интересные стартапы, бизнес идеи, удаленный бизнес. Обучающие бизнес материалы и курсы развития. Готовый бизнес и Франшизы." />
            <Container>
                <section className='py-4 py-sm-5'>
                    <h1 className="h2">Информация по сайту</h1>

                    <ul className='extra-fontc fs-12'>
                        {documents.items.length > 0 && documents.items.map(item =>
                            <li className='mb-2'>
                                <Link to={`/info/${item.id}`} className='color-1'>
                                    <u>{item.title}</u>
                                </Link>
                            </li>
                        )}

                    </ul>
                </section>


            </Container>
        </main>
    )
}

export default Info