import { apiRoutes } from '../config/api'
import { $api } from './index'

const getDocuments = async () => {
    const response = await $api.get(apiRoutes.DOCUMENTS)
    return response?.data
}
const getDocument = async (data) => {
    const response = await $api.get(apiRoutes.DOCUMENT, { params: data })
    return response?.data
}

export { getDocuments, getDocument }