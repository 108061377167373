import React, { useState } from 'react';
import { ImStarEmpty, ImStarFull } from "react-icons/im";

const BtnFav = ({ favo, onFav, className, favId }) => {

    return (
        <button type='button' className={'btn-fav ' + className} onClick={() => { onFav(favId) }}>
            {
                (favo)
                    ? <ImStarFull />
                    : <ImStarEmpty />
            }
        </button>
    )
}

export default BtnFav