import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import NavPagination from "../../components/NavPagination";
import Operation from "../../components/Operation";
import ReturnTitle from "../../components/utils/ReturnTitle";
import { customPrice } from "../../helpers/all";
import Meta from "../../components/Meta";
import { createTransaction, getTransactions } from "../../services/transaction"
import Loader from '../../components/utils/Loader';
import Input from '../../components/utils/Input';
import { NotificationManager } from 'react-notifications';
import { isPromo } from '../../services/promo';
import { setUser } from '../../store/reducers/authSlice';
import { Col, Row } from 'react-bootstrap';

const Finance = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [promo, setPromo] = useState()
  const [price, setPrice] = useState()

  const [loading, setLoading] = useState(false);
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [transactions, setTransactions] = useState({
    loading: true,
    items: [],
  });
  useEffect(() => {
    getTransactions({ page: currentPage })
      .then((res) => {
        setTransactions((prev) => ({
          prev,
          loading: false,
          ...res,
        }))
        setCurrentPage(res.pagination.currentPage)
      })
      .catch(() => setOrders((prev) => ({ ...prev, loading: false })));
  }, [currentPage]);
  const [balanceSection, setBalanceSection] = useState(2);
  const onPromo = useCallback(
    () => {
      isPromo({ promo: promo })
        .then((res) => {

          dispatch(setUser(res))
          NotificationManager.success("Промокод применён");
        })
        .catch((err) => {
          NotificationManager.error(
            err?.response?.data?.error ?? "Ошибка при применении"
          );
        })
        .finally(() => setPromo(""));
    },
    [promo]
  );
  const onPriceUp = useCallback((price) => {
    setLoading(true);
    createTransaction({ price: price })
      .then((res) => {
        if (res.data?.link) {
          return window.location.replace(res.data.link);
        }
        setLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error === "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
        setLoading(false);
      });
  }, []);
  console.log(price)
  if (transactions.loading) {
    return <Loader full />;
  }
  return (
    <section>
      <Meta title="Финансы" />
      <ReturnTitle link="/account" title="Мой кошелёк:" />
      <h4 className='d-none d-lg-block'>Мой кошелёк:</h4>
      <p className='fw-bold text-center fs-11 mb-3'>Баланс: {user?.cash ?? 0} ₽</p>
      <div className='finance'>
        <div className='item'>
          <Input
            type="number"
            placeholder="0"
            value={price}
            onChange={(e) => { setPrice(e) }}
            className="w-200 me-4"
          />
          <button
            type='button'
            disabled={!price || price <= 0 || loading}
            className='btn-primary'
            onClick={() => onPriceUp(price)}
          >Пополнить счет</button>
        </div>


        <div className='item'>
          <Input value={promo} placeholder="Ввести промокод" className="w-200 me-4" onChange={(e) => setPromo(e)} />
          <button type='button' disabled={!promo} className='btn-primary ' onClick={() => onPromo()}>Активировать</button>
        </div>
      </div>
      <p className="mt-4 text-center dark-gray">Для пополнения счета по безналичному <br />расчету необходимо скачать и заполнить форму. <br />После этого отправить нам на почту <br />.....@....... наш менеджер с Вами свяжется. </p>

      <button type='button' className='btn-gray mt-4 mx-auto'>Скачать форму</button>


      {/* <div className="list-wrapping mb-4 mb-sm-5">
        <div className="list-wrapping-top">
          <h5 className='fw-6'>Заголовок о комиссии</h5>
        </div>
        <div className="list-wrapping-main">
          <div className='d-sm-flex align-items-center justify-content-between'>
            <p className='fs-11'>Биржа предлагает самые выгодные условия для осуществления сделок. <br/>Операции облагаются комиссией 4%.</p>
            <button type='button' className='btn-1 d-flex align-items-center ms-sm-5 mt-4 mt-sm-0'>
              <span>Подробнее</span>
              <RxChevronRight className='fs-12'/>
            </button>
          </div>
        </div>
      </div> */}
      {/* 
      <div className="d-xl-flex mb-4 mb-sm-5">
        <div className="balance me-xl-3 me-xxl-5 mb-4 mb-xl-0">
          <span className="fw-6 me-2 me-xxl-3">Баланс</span>
          <span className="title-font fs-20 me-1">
            {customPrice(user.cash)}
          </span>
        </div>
        <ul className="list-unstyled d-flex justify-content-between justify-content-sm-start">
          <li className="me-1 me-sm-3 me-xxl-5">
            <button
              type="button"
              className={
                balanceSection === 1
                  ? "btn-2 active h-100 px-2 px-sm-4"
                  : "btn-2 h-100 px-2 px-sm-4"
              }
              onClick={() => setBalanceSection(1)}
            >
              Пополнить баланс
            </button>
          </li>
          <li className="me-1 me-sm-3 me-xxl-5">
            <button
              type="button"
              className={
                balanceSection == 2
                  ? "btn-2 active h-100 px-2 px-sm-4"
                  : "btn-2 h-100 px-2 px-sm-4"
              }
              onClick={() => setBalanceSection(2)}
            >
              История операций
            </button>
          </li>
          <li>
            <div className="btn-2 h-100 px-2 px-sm-4">Вывести средства</div>
          </li>
        </ul>
      </div>

      {balanceSection === 1 && (
        <section>
          <h3 className="mb-4">Выберите способ оплаты</h3>
          <ul className="list-unstyled row row-cols-4">
            <li>
              <label>
                <input type="radio" name="payment-method" />
                <span>Банковская карта</span>
              </label>
            </li>
            <li>
              <label>
                <input type="radio" name="payment-method" />
                <span>Банковская карта</span>
              </label>
            </li>
            <li>
              <label>
                <input type="radio" name="payment-method" />
                <span>Банковская карта</span>
              </label>
            </li>
            <li>
              <label>
                <input type="radio" name="payment-method" />
                <span>Банковская карта</span>
              </label>
            </li>
          </ul>
        </section>
      )}

      {balanceSection === 2 && (
        transactions?.pagination?.totalPages ? (
          <div className="list-wrapping">
            <div className="list-wrapping-top px-0">
              <ul className="line-operation">
                <li className="date">Дата</li>
                <li className="id">ID операции</li>
                <li className="type">Тип</li>
                <li className="stat">Статус</li>
                <li className="sum">Сумма</li>
              </ul>
            </div>
            <div className="list-wrapping-main">
              {transactions.loading ? (
                <div className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
                  Загрузка операций...
                </div>
              ) : (
                <ul className="row row-cols-1 row-cols-md-2 row-cols-xl-1 g-4 g-xl-0">
                  {transactions?.items.map((item) => (
                    <li>
                      <Operation {...item} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="list-wrapping-bottom">
              <NavPagination totalPages={transactions?.pagination?.totalPages} onPageChange={onPageChange} />
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <h3>
              Нет операций
            </h3>
          </div>
        )
      )} */}
    </section >
  );
};

export default Finance;
