import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBreadcrumbs from '../components/NavBreadcrumbs';
import BlogCard2 from '../components/BlogCard2';
import { getArticle } from '../services/article';
import NavPagination from '../components/NavPagination';
import Loader from '../components/utils/Loader';
import { declOfNum, getImageURL } from '../helpers/all';
import Meta from '../components/Meta';
import { MdKeyboardArrowLeft, MdCalendarMonth, MdOutlineRemoveRedEye } from "react-icons/md";
import moment from 'moment';

const Article = () => {
  const { id } = useParams();
  const [articles, setArticles] = useState({
    loading: true,
    items: [],
  });
  useEffect(() => {
    setArticles((prev) => ({ ...prev, loading: true }))
    getArticle({ id })
      .then((res) => {
        setArticles((prev) => ({
          prev,
          loading: false,
          ...res,
        }))
        setCurrentPage(res.documents.pagination.currentPage)
      })
      .catch(() => setArticles((prev) => ({ ...prev, loading: false })));
  }, [id]);


  const image = getImageURL({ path: articles?.media, size: "", type: "blog" })
  const declension = declOfNum(articles?.view, ['просмотр', 'просмотра', 'просмотров']);

  if (articles.loading) {
    return <Loader full />;
  }
  return (
    <main>
      <Meta title={"Статья " + articles?.title + " | (БМЛ)"} description={"Новости и Статьи: " + articles?.title + " " + articles?.content} />
      <Container>
        {/* <NavBreadcrumbs title="Новости" /> */}

        <section className='article-full py-4 py-sm-5 md-2 md-sm-3 mb-md-4'>
          <h1>{articles?.title}</h1>
          <div className="mt-4 dark-gray fs-09 d-flex justify-content-start align-items-center">
            <MdCalendarMonth className='fs-12 me-1' />
            <span className='me-3'>{moment(articles?.createdAt).format("DD MMMM YYYY")}</span>
            <MdOutlineRemoveRedEye className='fs-12 me-1' />
            <span className='me-3'>{articles?.view} {declension}</span>
          </div>
          <hr />
          <img src={image} alt={articles?.title} className='img-fluid mb-4' />
          <h3 className='mb-4'>{articles?.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: articles?.content }} />

          <div className="mt-4">
            <Link to="/blog" className='link fw-medium pb-1 bb-2'>
              <MdKeyboardArrowLeft className='fs-15' />
              <span className='ms-1'>Вернуться к остальным новотям</span>
            </Link>
          </div>
        </section>
      </Container>
    </main>
  );
};

export default Article;