import { React } from 'react';
import { useSwiper } from 'swiper/react';
import { MdNavigateBefore } from "react-icons/md";

const SwiperPrevButton = () => {
    const swiper = useSwiper();
    return (
        <button onClick={() => swiper.slideNext()} className='swiper-prev'>
            <MdNavigateBefore/>
        </button>
    )
}

export default SwiperPrevButton