import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useForm, useWatch } from 'react-hook-form';
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from 'react-router-dom';
import City from '../../components/utils/City';
import Input from '../../components/utils/Input';
import InputFileImg from '../../components/utils/InputFileImg';
import Loader from '../../components/utils/Loader';
import ReturnTitle from '../../components/utils/ReturnTitle';
import Select from '../../components/utils/Select';
import Textarea from '../../components/utils/Textarea';
import { getCategoryList, getUserCategory } from '../../services/category';
import { createUserProduct, deleteUserProduct, editUserProduct, getUserProduct } from '../../services/product';
import { useDispatch, useSelector } from 'react-redux';

const AddOffer = () => {
  const { id } = useParams();
  const [categories, setCategories] = useState({ items: [], loading: true });
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: "onSubmit",
  });
  const user = useSelector((state) => state.auth.user);
  const data = useWatch({ control })
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [selectedValues, setSelectedValues] = useState({});
  const [option, setOption] = useState({});
  const [sum, setSum] = useState(0);
  useEffect(() => {
    if (data.categoryId && sum > 1) {
      getUserCategory({ id: data.categoryId })
        .then((res) => {
          reset({
            ...data,
            category: res,
          });
        })
        .catch(() => setCategories((prev) => ({ ...prev, loading: false })));
    }
  }, [data.categoryId]);
  useEffect(() => {
    if (!id) {
      getCategoryList({ all: true })
        .then((res) => {
          setCategories(prev => ({ ...prev, items: res.sort((a, b) => a.commission - b.commission), loading: false }));
          reset({
            status: 2,
          });
        })
        .catch(() => setCategories((prev) => ({ ...prev, loading: false })));
    }
    else {
      getUserProduct({ id: id })
        .then((res) => {
          setCategories(prev => ({ ...prev, items: res.categories, loading: false }));
          reset({
            ...res.product,
            category: res.category,
            status: 2,
          });
        })
        .catch((error) => {
          NotificationManager.error(
            typeof error?.response?.data?.error == "string"
              ? error.response.data.error
              : "Неизвестная ошибка при загрузки страницы"
          )
        }
        );
    }

  }, [id]);
  const onSubmit = useCallback((data) => {
    if (!data?.cover) {
      return NotificationManager.error(
        "Небоходимо выбрать обложку объявления"
      )
    }
    if (data?.categoryId == 1) {
      if (!data?.title) {
        return NotificationManager.error(
          "Небоходимо заполнить название франшизы"
        )
      }
      if (!data?.desc) {
        return NotificationManager.error(
          "Небоходимо заполнить описание компании"
        )
      }
      if (!data?.data?.franchiseDesc) {
        return NotificationManager.error(
          "Небоходимо заполнить описание франшизы"
        )
      }
      if (!data?.data?.termsOfCooperation) {
        return NotificationManager.error(
          "Небоходимо заполнить условия сотрудничества"
        )
      }
      if (!data?.data?.startingInvestmentsFrom) {
        return NotificationManager.error(
          "Небоходимо заполнить стартовые инвестиции от"
        )
      }
      if (!data?.data?.lumpSumPayment) {
        return NotificationManager.error(
          "Небоходимо заполнить паушальный взнос"
        )
      }
      if (!data?.data?.royalty) {
        return NotificationManager.error(
          "Небоходимо заполнить роялти"
        )
      }
      if (data?.category?.params && data?.category?.params.length > 0 && (!data?.options || data?.category?.params.length != data?.options.length)) {
        return NotificationManager.error(
          "Небоходимо заполнить все обязательные поля"
        )
      }
      if (!data?.data?.city) {
        return NotificationManager.error(
          "Небоходимо заполнить город"
        )
      }
    }
    if (data?.categoryId == 2) {
      if (!data?.title) {
        return NotificationManager.error(
          "Небоходимо заполнить название объявления"
        )
      }
      if (!data?.desc) {
        return NotificationManager.error(
          "Небоходимо заполнить описание бизнеса"
        )
      }
      if (!data?.data?.termsOfSale) {
        return NotificationManager.error(
          "Небоходимо заполнить условия продажи"
        )
      }
      if (!data?.data?.amountOfPoints) {
        return NotificationManager.error(
          "Небоходимо заполнить количество точек"
        )
      }
      if (!data?.data?.businessCost) {
        return NotificationManager.error(
          "Небоходимо заполнить стоимость бизнеса"
        )
      }
      if (!data?.data?.netProfit) {
        return NotificationManager.error(
          "Небоходимо заполнить чистая прибыль"
        )
      }
      if (data?.category?.params && data?.category?.params.length > 0 && (!data?.options || data?.category?.params.length != data?.options.length)) {
        return NotificationManager.error(
          "Небоходимо заполнить все обязательные поля"
        )
      }
      if (!data?.data?.city) {
        return NotificationManager.error(
          "Небоходимо заполнить город"
        )
      }
    }
    if (data?.categoryId == 3) {
      if (!data?.title) {
        return NotificationManager.error(
          "Небоходимо заполнить название объявления"
        )
      }
      if (!data?.desc) {
        return NotificationManager.error(
          "Небоходимо заполнить описание объявления"
        )
      }
      if (!data?.data?.possibleInvestments) {
        return NotificationManager.error(
          "Небоходимо заполнить возможные инвестиции"
        )
      }
      if (data?.category?.params && data?.category?.params.length > 0 && (!data?.options || data?.category?.params.length != data?.options.length)) {
        return NotificationManager.error(
          "Небоходимо заполнить все обязательные поля"
        )
      }
      if (!data?.data?.city) {
        return NotificationManager.error(
          "Небоходимо заполнить город"
        )
      }
    }
    if (data?.categoryId == 4) {
      if (!data?.title) {
        return NotificationManager.error(
          "Небоходимо заполнить название объявления"
        )
      }
      if (!data?.desc) {
        return NotificationManager.error(
          "Небоходимо заполнить описание объявления"
        )
      }
      if (!data?.data?.termsOfCooperation) {
        return NotificationManager.error(
          "Небоходимо заполнить условия сотрудничества"
        )
      }
      if (!data?.data?.requiredInvestments) {
        return NotificationManager.error(
          "Небоходимо заполнить требуемые инвестиции"
        )
      }
      if (!data?.data?.projectStage) {
        return NotificationManager.error(
          "Небоходимо заполнить стадия проекта"
        )
      }
      if (data?.category?.params && data?.category?.params.length > 0 && (!data?.options || data?.category?.params.length != data?.options.length)) {
        return NotificationManager.error(
          "Небоходимо заполнить все обязательные поля"
        )
      }
      if (!data?.data?.city) {
        return NotificationManager.error(
          "Небоходимо заполнить город"
        )
      }
    }
    if (data?.categoryId == 5) {
      if (!data?.title) {
        return NotificationManager.error(
          "Небоходимо заполнить название объявления"
        )
      }
      if (!data?.desc) {
        return NotificationManager.error(
          "Небоходимо заполнить описание объявления"
        )
      }
      if (!data?.data?.termsOfCooperation) {
        return NotificationManager.error(
          "Небоходимо заполнить условия сотрудничества"
        )
      }
      if (!data?.data?.requiredInvestments) {
        return NotificationManager.error(
          "Небоходимо заполнить требуемые инвестиции"
        )
      }
      if (!data?.data?.projectStage) {
        return NotificationManager.error(
          "Небоходимо заполнить стадия проекта"
        )
      }
      if (data?.category?.params && data?.category?.params.length > 0 && (!data?.options || data?.category?.params.length != data?.options.length)) {
        return NotificationManager.error(
          "Небоходимо заполнить все обязательные поля"
        )
      }
      if (!data?.data?.city) {
        return NotificationManager.error(
          "Небоходимо заполнить город"
        )
      }
    }

    if (!id) {
      createUserProduct(data)
        .then(() => {
          NotificationManager.success("Объявление отправлено на модерацию");
          navigate(-1);
        })
        .catch((error) => {
          NotificationManager.error(
            typeof error?.response?.data?.error == "string"
              ? error.response.data.error
              : "Неизвестная ошибка при отправке"
          )
        }
        );
    }
    else {
      editUserProduct(data)
        .then(() => {
          NotificationManager.success("Объявление отправлено на модерацию");

          navigate(-1);
        })
        .catch(
          (err) => {
            err &&
              NotificationManager.error(
                err?.response?.data?.error ?? "Неизвестная ошибка при отправке"
              )
          }
        );
    }
  }, []);
  const onDelete = useCallback(() => {
    deleteUserProduct(id)
      .then(() => {
        NotificationManager.success("Объявление удалёно");

        navigate(-1);
      })
      .catch(
        (err) => {
          err &&
            NotificationManager.error(
              err?.response?.data?.error ?? "Неизвестная ошибка при отправке"
            )
        }
      );
  }, [id]);
  const formatNumber = (value) => {
    if (value < 1000) { return value; }
    else {
      return parseInt(value).toLocaleString('ru-RU')
    }
  };

  const handleSelectChange = (nodeId, selectedValue, i) => {
    setSelectedValues((prevSelectedValues) => {
      let hug = {};

      if (prevSelectedValues && prevSelectedValues[i]) {
        hug = { ...prevSelectedValues[i] };
      }
      if (hug[nodeId]) {
        let del = hug[nodeId]
        while (hug[del]) {
          let uda = hug[del];
          delete hug[del];
          del = uda;
        }
      }
      hug[nodeId] = selectedValue;

      return {
        ...prevSelectedValues,
        [i]: hug
      };
    });


    setValue(`options[${i}].optionId`, selectedValue)
  };
  const renderSelects = (tree, i) => {
    if (tree.children && tree.children.length > 0) {
      return (
        <>
          < Row className='mb-4' >
            <Col md={4} xxl={3}>
              {tree.title}<span className='red'>*</span>
            </Col>
            <Col md={8} xxl={9}>
              <Select
                value={selectedValues && selectedValues[i] && selectedValues[i][tree.id]}
                title={tree?.title}
                onClick={(e) => handleSelectChange(tree.id, e.value, i)}
                data={tree?.children?.sort((a, b) => a.priority - b.priority).map((item) => ({
                  value: item.id,
                  title: item.title,
                }))}
              />
            </Col>
          </Row>
          {selectedValues && selectedValues[i] && selectedValues[i][tree.id] &&
            renderSelects(tree.children.find((child) => child.id === selectedValues[i][tree.id]), i)}
        </>
      );
    }
    return null;
  };
  const createTree = (data, idProp, parentProp, parentId) =>
    data.filter((n) => parentId === (n[parentProp] ?? null))
      .map((n) => ({
        ...n,
        children: createTree(data, idProp, parentProp, n[idProp]),
      }));
  useEffect(() => {
    if (data.category) {
      let params = [];
      let param;
      if (data?.category?.params) {
        data.category.params.forEach((item, i) => {
          params[i] = { ...item };
          param = createTree(item.options.sort((a, b) => a.priority - b.priority), 'id', 'parent', null);
          params[i].children = param;
        });
      }
      setSelectedValues({});
      setOption(params);
      if (id && sum < 2) {
        if (data?.category?.params.length > 0) {
          let paramSort = data.category.params.sort((a, b) => a.priority - b.priority)
          for (let i = 0; i < paramSort.length; i++) {
            let optionsIndex = paramSort[i].options.findIndex((e) => e.id === data.options[i].optionId);
            let hug = paramSort[i].options[optionsIndex].parent;
            let opt = {};
            while (hug) {
              opt[hug] = paramSort[i].options[optionsIndex].id;
              optionsIndex = paramSort[i].options.findIndex((e) => e.id === hug);
              hug = paramSort[i].options[optionsIndex].parent;
            }
            hug = paramSort[i].options[optionsIndex].paramId;
            opt[hug] = paramSort[i].options[optionsIndex].id;
            setSelectedValues((prevSelectedValues) => ({
              ...prevSelectedValues,
              [i]: opt
            }));

          }

        }


      }

      setSum(2)
    }
  }, [data?.category?.id]);

  if (categories.loading) {
    return <Loader full />;
  }
  return (
    <section className='mb-3 mb-sm-5'>


      <div className='row'>
        {!user?.data?.status == 1 &&
          <p className="text-center light-bg p-3 mb-4">Для размещения объявлний нужно заполнить все обязательные поля в профиле</p>}
        <div className='col-12 col-xxl-11 col-xxxl-10'>
          {!id
            ? <>
              <ReturnTitle link={'/account/offers'} title={'Новое объявления'} />
              <h4 className='d-none d-lg-block'>Новое объявления</h4>
            </>
            : <>
              <ReturnTitle link={'/account/offers'} title={'Изменение объявления'} />
              <h4 className='d-none d-lg-block'>Изменение объявления</h4>
            </>
          }
          <form action="">
            <Row className='align-items-center mb-5'>
              <Col md={4} xxl={3}>
                <h6 className='text-uppercase'>Категория</h6>
              </Col>
              <Col md={8} xxl={9}>
                {categories?.items?.length > 0 && (
                  <Select
                    value={data.categoryId}
                    title="Выберите категорию"
                    onClick={(e) => {
                      reset({
                        categoryId: e.value,
                      }),
                        setSum(2)
                    }}
                    data={categories.items.map((item) => ({ value: item.id, title: item.title }))}
                  />
                )}
              </Col>
            </Row>

            <h6 className='text-uppercase mb-3'>Параметры</h6>
            {data?.categoryId == 1 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Название франшизы<span className='red'>*</span>
                </Col>
                <Col md={8} xxl={9}>
                  <Input
                    type={"title"}
                    placeholder={"Например, продажа офисных помещений"}
                    value={data?.title ?? ""}
                    onChange={e => setValue("title", e)}
                  />
                </Col>
              </Row>
            }
            {data?.categoryId != 1 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Название объявления<span className='red'>*</span>
                </Col>
                <Col md={8} xxl={9}>
                  <Input
                    type={"title"}
                    placeholder={"Например, продажа офисных помещений"}
                    value={data?.title ?? ""}
                    onChange={e => setValue("title", e)}
                  />
                </Col>
              </Row>
            }
            {(data?.categoryId != 1 && data?.categoryId != 2) &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Описание объявления<span className='red'>*</span>
                </Col>
                <Col md={8} xxl={9}>
                  <Textarea
                    type={"text"}
                    placeholder={"О бизнесе"}
                    value={data?.desc ?? ""}
                    onChange={e => setValue("desc", e)}
                  />
                </Col>
              </Row>
            }
            {data?.categoryId == 2 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Описание бизнеса<span className='red'>*</span>
                </Col>
                <Col md={8} xxl={9}>
                  <Textarea
                    type={"text"}
                    placeholder={"Описание бизнеса"}
                    value={data?.desc ?? ""}
                    onChange={e => setValue("desc", e)}
                  />
                </Col>
              </Row>
            }
            {data?.categoryId == 1 &&
              <>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Описание компании<span className='red'>*</span>
                  </Col>
                  <Col md={8} xxl={9}>
                    <Textarea
                      type={"text"}
                      placeholder={"Описание компании"}
                      value={data?.desc ?? ""}
                      onChange={e => setValue("desc", e)}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Описание франшизы<span className='red'>*</span>
                  </Col>
                  <Col md={8} xxl={9}>
                    <Textarea
                      type={"text"}
                      placeholder={"Описание франшизы"}
                      value={data?.data?.franchiseDesc ?? ""}
                      onChange={e => setValue("data.franchiseDesc", e)}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Преимущества франшизы
                  </Col>
                  <Col md={8} xxl={9}>
                    <Textarea
                      type={"text"}
                      placeholder={"Преимущества франшизы"}
                      value={data?.data?.franchiseBenefits ?? ""}
                      onChange={e => setValue("data.franchiseBenefits", e)}
                    />
                  </Col>
                </Row>
              </>
            }
            {data?.categoryId == 2 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Условия продажи<span className='red'>*</span>
                </Col>
                <Col md={8} xxl={9}>
                  <Textarea
                    type={"text"}
                    placeholder={"Условия продажи"}
                    value={data?.data?.termsOfSale ?? ""}
                    onChange={e => setValue("data.termsOfSale", e)}
                  />
                </Col>
              </Row>
            }
            {data?.categoryId != 2 && data?.categoryId != 3 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Условия сотрудничества<span className='red'>*</span>
                </Col>
                <Col md={8} xxl={9}>
                  <Textarea
                    type={"text"}
                    placeholder={"Условия сотрудничества"}
                    value={data?.data?.termsOfCooperation ?? ""}
                    onChange={e => setValue("data.termsOfCooperation", e)}
                  />
                </Col>
              </Row>
            }
            {data?.categoryId == 3 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Предполагаемые условия сотрудничества
                </Col>
                <Col md={8} xxl={9}>
                  <Textarea
                    type={"text"}
                    placeholder={"Предполагаемые условия сотрудничества"}
                    value={data?.data?.estimatedTermsOfCooperation ?? ""}
                    onChange={e => setValue("data.estimatedTermsOfCooperation", e)}
                  />
                </Col>
              </Row>
            }
            {data?.categoryId != 3 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Бизнес-план
                </Col>
                <Col md={8} xxl={9}>
                  <Textarea
                    type={"text"}
                    placeholder={"Бизнес-план"}
                    value={data?.data?.businessPlan ?? ""}
                    onChange={e => setValue("data.businessPlan", e)}
                  />
                </Col>
              </Row>
            }
            {data?.categoryId != 2 && data?.categoryId != 1 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  О себе
                </Col>
                <Col md={8} xxl={9}>
                  <Textarea
                    type={"text"}
                    placeholder={"О себе"}
                    value={data?.data?.about ?? ""}
                    onChange={e => setValue("data.about", e)}
                  />
                </Col>
              </Row>
            }
            <Row className='mb-4'>
              <Col md={4} xxl={3}>
                <p className='mb-1'>Обложка объявления<span className="red">*</span></p>
                <p className='dark-gray fs-09'>Размер 600х400</p>
              </Col>
              <Col md={8} xxl={9}>
                <InputFileImg media={data?.cover} setImage={e => setValue("cover", e)} />
              </Col>
            </Row>

            <Row className='mb-4'>
              <Col md={4} xxl={3}>
                <p className='mb-1'>Фотогралерея</p>
                <p className='dark-gray fs-09'>Не более 10</p>
              </Col>
              <Col md={8} xxl={9}>
                {/* нужно доделать версию с множественной загрузкой */}
                <InputFileImg media={data?.medias} setImage={e => setValue("medias", e)} multiple={true} />
              </Col>
            </Row>
            {data?.categoryId == 1 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Загрузить видео
                </Col>
                <Col md={8} xxl={9}>
                  <Input
                    type={"title"}
                    placeholder={"Вставить ссылку"}
                    value={data?.data?.video}
                    onChange={e => setValue("data.video", e)}
                  />
                </Col>
              </Row>
            }
            <Row className='mb-4'>
              <Col md={4} xxl={3}>
                Город<span className='red'>*</span>
              </Col>
              <Col md={8} xxl={9}>
                <City value={data?.data?.city} setCity={(e) => setValue("data.city", e)} city={data?.data?.city} />
              </Col>
            </Row>
            {option && option.length > 0 && [...option].sort((a, b) => a.priority - b.priority).map((item, i) => {
              return renderSelects(item, i);
            })}



            {data?.categoryId != 3 && data?.categoryId != 2 && data?.categoryId != 1 &&
              <>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Требуемые инвестиции<span className='red'>*</span>
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0, ₽ "}
                      value={data?.data?.requiredInvestments && formatNumber(data?.data?.requiredInvestments)}
                      onChange={e => setValue("data.requiredInvestments", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Предполагаемая прибыль / мес
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0, ₽ "}
                      value={data?.data?.estimatedProfitMeme && formatNumber(data?.data?.estimatedProfitMeme)}
                      onChange={e => setValue("data.estimatedProfitMeme", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
              </>
            }
            {data?.categoryId == 1 &&
              <>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Стартовые инвестиции от<span className='red'>*</span>
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0, ₽ "}
                      value={data?.data?.startingInvestmentsFrom && formatNumber(data?.data?.startingInvestmentsFrom)}
                      onChange={e => setValue("data.startingInvestmentsFrom", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Паушальный взнос<span className='red'>*</span>
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0, ₽ "}
                      value={data?.data?.lumpSumPayment && formatNumber(data?.data?.lumpSumPayment)}
                      onChange={e => setValue("data.lumpSumPayment", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Роялти, %<span className='red'>*</span>
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0 %"}
                      value={data?.data?.royalty && formatNumber(data?.data?.royalty)}
                      onChange={e => setValue("data.royalty", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Срок окупаемости от
                  </Col>
                  <Col md={8} xxl={9}>
                    <select value={data?.data?.paybackPeriodFrom ?? 0} className='w-100' onChange={(e) => setValue("data.paybackPeriodFrom", e.target.value)}>
                      <option value="0" disabled>0</option>
                      <option value="до 6 месяцев">До 6 месяцев</option>
                      <option value="от 6 месяцев до 1 года">От 6 месяцев до 1 года</option>
                      <option value="от 1 до 3 лет">От 1 до 3 лет</option>
                      <option value="от 3 до 6 лет">От 3 до 6 лет</option>
                      <option value="от 6 лет и выше">От 6 лет и выше</option>
                    </select>
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Предполагаемая прибыль
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0, ₽ "}
                      value={data?.data?.estimatedProfit && formatNumber(data?.data?.estimatedProfit)}
                      onChange={e => setValue("data.estimatedProfit", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Год основания компании
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0"}
                      value={data?.data?.yearTheCompanyWasFounded && formatNumber(data?.data?.yearTheCompanyWasFounded)}
                      onChange={e => setValue("data.yearTheCompanyWasFounded", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Количество собственных точек
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0"}
                      value={data?.data?.numberOfOwnPoints && formatNumber(data?.data?.numberOfOwnPoints)}
                      onChange={e => setValue("data.numberOfOwnPoints", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Количество проданных франшиз
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0"}
                      value={data?.data?.numberOfFranchisesSold && formatNumber(data?.data?.numberOfFranchisesSold)}
                      onChange={e => setValue("data.numberOfFranchisesSold", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>

              </>
            }
            {data?.categoryId == 3 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Возможные инвестиции<span className='red'>*</span>
                </Col>
                <Col md={8} xxl={9}>
                  <Input
                    type={"money"}
                    placeholder={"0, ₽ "}
                    value={data?.data?.possibleInvestments && formatNumber(data?.data?.possibleInvestments)}
                    onChange={e => setValue("data.possibleInvestments", e.replace(/[^0-9.,]/g, ''))}
                  />
                </Col>
              </Row>
            }
            {data?.categoryId == 2 &&
              <>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Количество точек<span className='red'>*</span>
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0"}
                      value={data?.data?.amountOfPoints && formatNumber(data?.data?.amountOfPoints)}
                      onChange={e => setValue("data.amountOfPoints", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Стоимость бизнеса<span className='red'>*</span>
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}
                      placeholder={"0, ₽ "}
                      value={data?.data?.businessCost && formatNumber(data?.data?.businessCost)}
                      onChange={e => setValue("data.businessCost", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
              </>
            }
            {data?.categoryId != 1 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Окупаемость
                </Col>
                <Col md={8} xxl={9}>
                  <select value={data?.data?.payback ?? 0} className='w-100' onChange={(e) => setValue("data.payback", e.target.value)}>
                    <option value="0" disabled>Выберите срок окупаемости</option>
                    <option value="до 6 месяцев">До 6 месяцев</option>
                    <option value="от 6 месяцев до 1 года">От 6 месяцев до 1 года</option>
                    <option value="от 1 до 3 лет">От 1 до 3 лет</option>
                    <option value="от 3 до 6 лет">От 3 до 6 лет</option>
                    <option value="от 6 лет и выше">От 6 лет и выше</option>
                  </select>
                </Col>
              </Row>
            }

            {data?.categoryId == 2 &&
              <>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Оборот в месяц
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0, ₽ "}
                      value={data?.data?.turnoverPerMonth && formatNumber(data?.data?.turnoverPerMonth)}
                      onChange={e => setValue("data.turnoverPerMonth", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
                <Row className='mb-4'>
                  <Col md={4} xxl={3}>
                    Чистая прибыль<span className='red'>*</span>
                  </Col>
                  <Col md={8} xxl={9}>
                    <Input
                      type={"money"}

                      placeholder={"0, ₽ "}
                      value={data?.data?.netProfit && formatNumber(data?.data?.netProfit)}
                      onChange={e => setValue("data.netProfit", e.replace(/[^0-9.,]/g, ''))}
                    />
                  </Col>
                </Row>
              </>
            }
            {data?.categoryId != 3 && data?.categoryId != 2 && data?.categoryId != 1 &&
              <Row className='mb-4'>
                <Col md={4} xxl={3}>
                  Стадия проекта<span className='red'>*</span>
                </Col>
                <Col md={8} xxl={9}>
                  <select value={data?.data?.projectStage ?? 0} className='w-100' onChange={(e) => setValue("data.projectStage", e.target.value)}>
                    <option value="0" disabled>Выберите стадию проекта</option>
                    <option value="Идея">Идея</option>
                    <option value="В процессе создания">В процессе создания</option>
                    <option value="Действующий бизнес">Действующий бизнес</option>
                  </select>
                </Col>
              </Row>
            }
            {data?.categoryId == 1 && !id &&
              <Row className='mb-4'>
                <Col md={6} xxl={4}>
                  <label className='radio'>
                    <div className='d-flex'>
                      <input
                        type="radio"
                        value="0"
                        name="price"
                        checked={data?.data?.typePrice == "0"}
                        onClick={(e) => setValue("data.typePrice", e.target.value)}
                      />
                      <span className="ms-2">Разместить на 3 месяца</span>
                    </div>
                    <strong className="mt-2">8 000 Р</strong>
                  </label>
                </Col>
                <Col md={6} xxl={4}>
                  <label className='radio'>
                    <div className='d-flex'>
                      <input
                        type="radio"
                        name="price"
                        value="1"
                        checked={data?.data?.typePrice == "1"}
                        onClick={(e) => setValue("data.typePrice", e.target.value)}
                      />
                      <span className="ms-2">Разместить на 6 месяца</span>
                    </div>
                    <strong className="mt-2">14 000 Р</strong>
                  </label>
                </Col>
                {/* <Col md={6} xxl={4}>
                  <button
                    type='button'
                    className={'btn-premium' + (data?.data?.price == "3" ? " active" : "")}
                    onClick={() => setValue("data.price", 3)}
                  ><h3 className='mb-0'>Premium-размещение</h3></button>
                </Col> */}
              </Row>
            }
            {!id ? user?.data?.status == 1 &&
              <button
                type='button'
                disabled={data?.categoryId == 1 && user?.data?.type == "individual"}
                className='btn-primary mt-4 mt-sm-5'
                onClick={handleSubmit(onSubmit)}
              >Отправить на модерацию</button>
              :
              <Row>
                <Col md={4} xxl={3}>
                  <button
                    type='button'
                    className='btn-primary mt-4 mt-sm-5'
                    onClick={handleSubmit(onSubmit)}
                  >Сохранить изменения</button>
                </Col>
                <Col md={4} xxl={3}>
                  <button
                    type='button'
                    className='btn-primary mt-4 mt-sm-5'
                    onClick={() => onDelete()}
                  >Удалить объявление</button>
                </Col>
              </Row>
            }
            {!user?.data?.status == 1 &&
              <>
                <p className="text-center light-bg p-3 mb-4">Для размещения объявлний нужно заполнить все обязательные поля в профиле</p>
                <Link to="/account/settings" className='btn-primary mt-4 mt-sm-5'>Редактировать профиль</Link>
              </>
            }
          </form>
        </div>
      </div >
    </section >
  );
};

export default AddOffer;