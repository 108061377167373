import React from 'react';
import { Container } from "react-bootstrap";

const InfoInNums = () => {
  return (
    <section className="infoInNums mb-5">
        <Container>
            <ul className="list-unstyled row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 justify-content-center g-3 g-lg-4">
                <li>
                    <div className="infoInNums-item">
                    <div>
                        <div className="fw-5 mb-4">Поиск инвесторов</div>
                        <div className="fs-09 extra-font">Более <span className="color-2 fw-7">400</span> зарегистрированных инвесторов</div>
                    </div>
                    <div>
                        <img src="/imgs/icons/icon-1.svg" alt="Поиск инвесторов" />
                    </div>
                    </div>
                </li>
                <li>
                    <div className="infoInNums-item">
                    <div>
                        <div className="fw-5 mb-4">Зарегистрировано пользователей</div>
                        <div className="fs-09 extra-font">Более <span className="color-2 fw-7">7000</span> предпринимателей</div>
                    </div>
                    <div>
                        <img src="/imgs/icons/icon-2.svg" alt="Зарегистрировано пользователей" />
                    </div>
                    </div>
                </li>
                <li>
                    <div className="infoInNums-item">
                    <div>
                        <div className="fw-5 mb-4">Продажа бизнеса</div>
                        <div className="fs-09 extra-font">Более <span className="color-2 fw-7">600</span> проданных объектов</div>
                    </div>
                    <div>
                        <img src="/imgs/icons/icon-3.svg" alt="Продажа бизнеса" />
                    </div>
                    </div>
                </li>
                <li>
                    <div className="infoInNums-item">
                    <div>
                        <div className="fw-5 mb-4">Поиск франшизы</div>
                        <div className="fs-09 extra-font">Более <span className="color-2 fw-7">50</span> компаний</div>
                    </div>
                    <div>
                        <img src="/imgs/icons/icon-4.svg" alt="Поиск франшизы" />
                    </div>
                    </div>
                </li>
            </ul>
        </Container>
    </section>
  )
}

export default InfoInNums