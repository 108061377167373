import React from 'react';
import { Outlet } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import AccountMenu from '../pages/account/AccountMenu';
import ScrollToTopButton from '../components/utils/ScrollToTopButton';

const AccountLayout = ({ isMobile }) => {
  return (
    <main className='account'>
      <Container className='account-container'>
        <ScrollToTopButton />
        {
          (isMobile)
            ? <Outlet />
            : <>
              <AccountMenu />
              <div className="account-main">
                <Outlet />
              </div>
            </>
        }
      </Container>
    </main>
  );
};

export default AccountLayout;