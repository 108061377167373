// value - цена, currency - выводить валюту (true|false))
import { LiaRubleSignSolid } from "react-icons/lia";
import { FILE_URL } from "../config/api";
import PhotoPlacement from "../assets/imgs/photo-placement.jpg";
import { useEffect, useState } from "react";

const customPrice = (value, currency = true) => {
  if (!value) {
    return 0 + "₽";
  }
  value = parseInt(value).toLocaleString();
  if (currency) {
    value = (
      <>
        {value}<LiaRubleSignSolid className="ruble" />
      </>
    );
  }
  return value;
};
const declOfNum = (number, titles) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

const Timer = ({ update = false, value = 60, onEnd }) => {
  const [counter, setCounter] = useState(value);

  // Third Attempts
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      onEnd()
    }
    return () => clearInterval(timer);
  }, [counter, update]);

  return counter
}


const treeAll = (item, options) => {
  let j = 0;
  let spanOpt = [];
  spanOpt[j] = item?.option;
  let optId = item?.option;
  while (optId?.parent) {
    optId = options.find((e) => e.id === optId?.parent);
    j++;
    spanOpt[j] = optId;
  }
  spanOpt = spanOpt.reverse();
  return spanOpt;
}

const getImageURL = ({ path = "", size = "mini", type = "user" }) => {
  if (path && Array.isArray(path) && path?.length > 0) {
    if (size == "mini") {
      return FILE_URL + "/" + type + "/mini/" + path[0].media;
    } else {
      return FILE_URL + "/" + type + "/" + path[0].media;
    }
  } else if (path && path?.length > 0) {
    if (size == "mini") {
      return FILE_URL + "/" + type + "/mini/" + path;
    } else {
      return FILE_URL + "/" + type + "/" + path;
    }
  }
  else if (type == 'product/cover') {
    return "/imgs/img4.jpg";
  }

  else if (!type || type == 'product' || type == 'sale' || type == 'banner' || type == 'category' || type == 'blog') {
    return "/imgs/img5.jpg";
  } else if (type == 'user') {
    return PhotoPlacement;
  }
};

export { customPrice, getImageURL, declOfNum, treeAll, Timer };