import React, { useCallback } from 'react';
import ReturnTitle from '../../components/utils/ReturnTitle';
import { createTask } from '../../services/task';
import { NotificationManager } from 'react-notifications';

const Advertising = () => {
  const onTask = useCallback((task) => {
    createTask({ type: "task", ...task })
      .then(() => {
        NotificationManager.success("Заявка отправлена");

      })
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при отправке"
        );
      });
  }, []);
  return (
    <section>
      <ReturnTitle link={'/account'} title={'Рекламный кабинет'} />
      <ul className='mt-4 list-unstyled'>
        <li className='mb-4'>
          <div className="account-reklama">
            <img
              src="/imgs/banner1.jpg"
              alt="userphoto"
              className='account-reklama-img'
            />
            <div className='account-reklama-info'>
              <h6 className='color-1 mb-1'>Баннер на главной странице.</h6>
              <p className='fs-09 fw-medium mb-2'>50 000 просмотров в месяц.</p>
              <p className='fs-09 mb-2'>Цена по запросу.</p>
              <div className='account-reklama-btns'>
                <button type='button' className='btn-primary' onClick={() => onTask({ title: "Баннер на главной странице." })}>Оставить заявку</button>
              </div>
            </div>

          </div>
        </li>
        <li className='mb-4'>
          <div className="account-reklama">
            <img
              src="/imgs/banner2.jpg"
              alt="userphoto"
              className='account-reklama-img'
            />
            <div className='account-reklama-info'>
              <h6 className='color-1 mb-1'>Баннер в разделах.</h6>
              <p className='fs-09 fw-medium mb-2'>35 000 просмотров в месяц.</p>
              <p className='fs-09 mb-2'>3 месяца - <strong>24 000 Р</strong></p>
              <p className='fs-09 mb-2'>6 месяцев - <strong>42 000 Р</strong></p>
              <div className='account-reklama-btns'>
                <button type='button' className='btn-primary' onClick={() => onTask({ title: "Баннер в разделах." })}>Оставить заявку</button>
              </div>
            </div>

          </div>
        </li>
        <li className='mb-4'>
          <div className="account-reklama">
            <img
              src="/imgs/banner3.jpg"
              alt="userphoto"
              className='account-reklama-img'
            />
            <div className='account-reklama-info'>
              <h6 className='color-1 mb-1'>Баннер в объявлениях.</h6>
              <p className='fs-09 fw-medium mb-2'>35 000 просмотров в месяц.</p>
              <p className='fs-09 mb-2'>3 месяца - <strong>24 000 Р</strong></p>
              <p className='fs-09 mb-2'>6 месяцев - <strong>42 000 Р</strong></p>
              <div className='account-reklama-btns'>
                <button type='button' className='btn-primary' onClick={() => onTask({ title: "Баннер в объявлениях." })}>Оставить заявку</button>
              </div>
            </div>

          </div>
        </li>
      </ul>
    </section >
  );
};

export default Advertising;