import React, { useCallback, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import { login } from "../../services/auth";

const TelegramLogin = () => {
  const isAuth = useSelector((state) => state?.auth?.isAuth);
  const navigate = useNavigate();
  console.log(isAuth)
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      return navigate("/account/profile");
    }
  }, [isAuth]);
  const onSubmit = useCallback(async (key) => {
    try {
      const text = await navigator.clipboard.readText();
      console.log(text)
      if (text) {
        dispatch(login({ data: text }));
      }
    } catch (error) {
      console.error('Ошибка при чтении из буфера обмена: ', error);
    }
  }, [dispatch]);


  return (
    <main>
      <Meta title="Авторизация через телеграм" />
      <Container>
        <section className="hv-100 sec-login d-flex flex-column align-items-center justify-content-center">
          <h1 className="h3 text-center">Скопируйте код, отправленный в телеграм и нажмите на кнопку</h1>
          <Row className='g-3 g-md-4 mb-5 mt-1 justify-content-center activate'>

            <button type='button' className='btn-1 h-100 w-100' onClick={() => onSubmit()}>Вставить и отправить код</button>
          </Row>
        </section>

      </Container>
    </main>
  );
};

export default TelegramLogin;
