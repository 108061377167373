import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../../services/auth';


const AccountMenu = (props) => {
  const dispatch = useDispatch();
  const unreadCount = useSelector((state) => state.notification.message);
  const dataStatus = useSelector((state) => state?.auth?.user?.data?.status);
  return (
    <nav className='account-nav'>
      <ul>
        <li>
          <NavLink to="/account/profile">
            <div>Профиль</div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/account/info">
            <div>Как загрузить объявление</div>
          </NavLink>
        </li>
        {dataStatus && <>
          <li>
            <NavLink to="/account/offers">
              <div>Мои объявления</div>
            </NavLink>
          </li>
          <li >
            <NavLink to="/account/messages">
              <div>Онлайн чат</div>
              {unreadCount && unreadCount > 0 ? <span className=" message">{parseInt(unreadCount)}</span> : ""}
            </NavLink>

          </li>
          <li>
            <NavLink to="/account/favs">
              <div>Избранные объявления</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/account/finance">
              <div>Мой кошелек</div>
            </NavLink>
          </li>
          <li>
            <NavLink to="/account/advertising">
              <div>Рекламный кабинет</div>
            </NavLink>
          </li>
        </>}
        <li>
          <NavLink to="/account/settings">
            <div>Настройки профиля</div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/" onClick={() => dispatch(logout())} >
            <div>Выйти из профиля</div>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default AccountMenu;