import React, { useEffect, useState } from 'react';
import { FiPaperclip } from "react-icons/fi";
import { getImageURL } from '../../helpers/all';

const imageMimeType = /image\/(png|jpg|jpeg)/i;

export default function InputFileImg({ media, setImage, className, multiple }) {
  const [fileDataURLs, setFileDataURLs] = useState([]);

  const changeHandler = (e) => {
    setFileDataURLs([]);
    const filesArray = Array.from(e.target.files);

    const validFiles = filesArray.filter(file => file.type.match(imageMimeType));

    if (validFiles.length === 0) {
      alert("No valid image files selected");
      return;
    }

    const newFiles = validFiles.slice(0, 10); // Получаем только первые 10 файлов

    if (newFiles.length > 0) {

      const fileReaders = newFiles.map(file => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            setFileDataURLs(prevDataUrls => [...prevDataUrls, result]);
          }
        };
        reader.readAsDataURL(file);
        return reader;
      });

      if (multiple) {
        newFiles.map((file, i) => {
          setImage([...newFiles]);
        })
      } else {
        setImage(newFiles[0]);
      }

    }
  }


  useEffect(() => {
    return () => {
      setFileDataURLs([]); // Очищаем изображения при размонтировании компонента
    }
  }, []);
  return (
    <div className='files'>
      <label className={'files-label ' + className}>
        <input
          type="file"
          id='image'
          accept='.png, .jpg, .jpeg'
          multiple={multiple}
          onChange={changeHandler}
        />
        <div className="icon"><FiPaperclip /></div>
        <span className='blue ms-2'>Прикрепить файл</span>
      </label>
      {fileDataURLs.length > 0 &&
        <ul className='files-list'>
          {fileDataURLs.map((dataURL, index) => (
            <li className="me-1 mt-1" key={index}>
              <img src={dataURL} alt={`preview ${index + 1}`} />
            </li>
          ))}
        </ul>
      }
      {fileDataURLs.length == 0 && media &&
        (!multiple ?
          <ul className='files-list'>
            <li className="me-1 mt-1" >
              <img src={getImageURL({ path: media, size: "mini", type: "product/cover" })} alt={`preview 1`} />
            </li>

          </ul>
          :
          <ul className='files-list'>

            {media?.map((item, i) => (
              <li className="me-1 mt-1" key={i}>
                <img src={getImageURL({ path: item?.media, type: "product/media" })} alt={`preview ${i + 1}`} />
              </li>
            ))}

          </ul>

        )
      }
    </div >
  );
};
