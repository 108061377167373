import React, { useCallback, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import AdvCard from '../../components/AdvCard';
import ReturnTitle from "../../components/utils/ReturnTitle";
import { useDispatch, useSelector } from 'react-redux';
import { getFavorites, toggleFavorite } from '../../services/favorite';
import NavPagination from '../../components/NavPagination';

const Favorites = () => {
  const favorites = useSelector((state) => state.favorite.items);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFavorites());

  }, []);
  const onFav = useCallback((e) => {
    dispatch(toggleFavorite({ productId: e, }));
  }, []);

  return (
    <section>
      <ReturnTitle link="/account" title="Избранные объявления" />
      {favorites && favorites?.length > 0 ?
        <Row xs={2} md={3} className="justify-content-start g-2 g-sm-3 g-xl-4">
          {favorites.map(item =>
            <Col key={item?.product?.id}><AdvCard {...item.product} favo={true} onFav={onFav} onSearch={() => { handleCloseSearch() }} /></Col>
          )}
        </Row>
        :
        <h3 className="text-center">Вы пока не добавили себе избранных объявлений.</h3>

      }
    </section>
  )
}

export default Favorites