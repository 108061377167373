import React from 'react';
import { RxChevronLeft, RxChevronRight } from "react-icons/rx";
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';

const NavPagination = ({ onPageChange, totalPages, currentPage }) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  return (
    totalPages > 1 && (
      <ReactPaginate
        forcePage={currentPage - 1}
        pageCount={totalPages}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousLabel={isFirstPage ? null : <RxChevronLeft />}
        nextLabel={!isLastPage && <RxChevronRight />}
        breakLabel={'...'}
        onPageChange={onPageChange}
        containerClassName={'pagination'}
        pageClassName={'button '}
        pageLinkClassName={'button'}
        activeClassName={'active'}
        breakClassName={'break-item'}
        breakLinkClassName={'break-link'}
        previousClassName={classNames('button', { 'button-inactive': isFirstPage })}
        previousLinkClassName={classNames('button', { 'button-inactive': isFirstPage })}
        nextClassName={classNames('button', { 'button-inactive': isLastPage })}
        nextLinkClassName={classNames('button', { 'button-inactive': isLastPage })}
      />
    )
  );
};

export default React.memo(NavPagination);
