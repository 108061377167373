import React, { useEffect, useState } from 'react';
import { MdArrowUpward } from "react-icons/md";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Показывать кнопку, когда скролл превышает 100 пикселей
    const toggleVisibility = () => {
        if (window.pageYOffset > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Добавить слушателя событий при монтировании компонента
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    // Прокрутить страницу к началу
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Плавная анимация скролла в наверх
        });
    };

    return (
        <button type='button' className={isVisible ? "scroll-to-top show" : "scroll-to-top"} onClick={scrollToTop}>
            <MdArrowUpward />
        </button>
    );
};

export default ScrollToTopButton;