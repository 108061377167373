import React, { useCallback, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ReturnTitle from '../../components/utils/ReturnTitle';
import PartnerRequest from '../../components/PartnerRequest';
import { editSubscription, getSubscriptions, getSubscriptionsSearch } from '../../services/subscription';
import Loader from '../../components/utils/Loader';
import NavPagination from '../../components/NavPagination';
import useDebounce from '../../hooks/useDebounce';

const Partners = () => {
  const [incoming, setIncoming] = useState(true);
  const [prevIncoming, setPrevIncoming] = useState(incoming);
  const [prevSearch, setPrevSearch] = useState();
  const [subscriptions, setSubscriptions] = useState({
    loading: true,
    items: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 800);
  const getPage = () => {
    if (incoming == "all") {
      setSubscriptions((prev) => ({ ...prev, loading: true }));
      getSubscriptionsSearch({ size: 12, page: currentPage, search: debouncedSearch })
        .then((res) => {
          setSubscriptions((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }));
        })
        .catch(() => setSubscriptions((prev) => ({ ...prev, loading: false })));
    }
    else {
      setSubscriptions((prev) => ({ ...prev, loading: true }));
      getSubscriptions({ to: incoming, size: 12, page: currentPage, search: debouncedSearch })
        .then((res) => {
          setSubscriptions((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }));
        })
        .catch(() => setSubscriptions((prev) => ({ ...prev, loading: false })));
    }
  };
  const onSub = useCallback((data) => {
    editSubscription({ to: data })
      .then((res) => {
        res && getPage(incoming)
      })

  }, [incoming]);
  useEffect(() => {
    if (incoming !== prevIncoming || debouncedSearch !== prevSearch) {
      if (currentPage == 1) {
        getPage();
      }
      else {
        setCurrentPage(1); // Установка currentPage в 1 при изменении id или sort
      }
    }
    else {
      getPage();
    }
    // Сохраняем предыдущие значения id и sort
    setPrevIncoming(incoming);
    setPrevSearch(debouncedSearch);
  }, [currentPage, debouncedSearch, incoming]);

  return (
    <section>
      <ReturnTitle link={'/account'} title={'Бизнес-партнёры'} />

      <Tab.Container defaultActiveKey="first">
        <div className="d-lg-flex flex-lg-row-reverse align-items-center mb-4">
          <form action="" className='search-form ms-lg-auto mb-4 mb-lg-0'>
            <input value={search} type="search" placeholder='Поиск' onChange={(event) => setSearch(event.target.value)} />
          </form>

          <Nav variant="tabs" defaultActiveKey="first">
            <Nav.Item>
              <Nav.Link eventKey="first" onClick={() => { setIncoming(true); }}>
                Подписки <span className='gray'>{subscriptions?.countFrom}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second" onClick={() => { setIncoming(false); }}>
                Подписчики <span className='red'>{subscriptions?.countTo}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third" onClick={() => { setIncoming("all"); }}>
                Поиск
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content>
          {subscriptions?.loading && <Loader full />}
          <Tab.Pane eventKey="first">
            <ul className='list-unstyled'>
              {subscriptions?.items?.length > 0 && subscriptions.items.map(item =>
                <li className='mb-4' key={item.id}>
                  <PartnerRequest incoming={incoming} {...item} onSub={onSub} />
                </li>
              )}
            </ul>
          </Tab.Pane>
          <Tab.Pane eventKey="second">
            <ul className='list-unstyled'>
              {subscriptions?.items?.length > 0 && subscriptions.items.map(item =>
                <li className='mb-4' key={item.id}>
                  <PartnerRequest incoming={incoming} {...item} onSub={onSub} />
                </li>
              )}
            </ul>
          </Tab.Pane>
          <Tab.Pane eventKey="third">
            <ul className='list-unstyled'>
              {subscriptions?.items?.length > 0 && subscriptions.items.map(item =>
                <li className='mb-4' key={item.id}>
                  <PartnerRequest incoming={incoming} item={item} onSub={onSub} />
                </li>
              )}
            </ul>
          </Tab.Pane>
        </Tab.Content>
        <NavPagination totalPages={subscriptions.pagination?.totalPages} onPageChange={onPageChange} currentPage={currentPage} />
      </Tab.Container>
    </section>
  );
};

export default Partners;
