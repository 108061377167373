import React from 'react';
import { Link } from "react-router-dom";
import { MdOutlineStarOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import BtnFav from './utils/BtnFav';
import { ImPencil } from "react-icons/im";
import { getImageURL } from '../helpers/all';
import { useSelector } from 'react-redux';
import useIsMobile from '../hooks/isMobile';

const AdvCard = ({ title, cover, uid, onFav, id, favo, user, data, categoryId, onSearch }) => {
  const isMobile = useIsMobile('991px')
  const userId = useSelector(state => state.auth?.user?.id);
  const dataStatus = useSelector((state) => state?.auth?.user?.data?.status);

  if (!favo) {
    var favorite = useSelector((state) => state.favorite.items);
    favo = favorite?.find(el => el?.product?.id == id) ? 1 : 0;
  }
  const priceMap = {
    1: data?.startingInvestmentsFrom,
    2: data?.businessCost,
    3: data?.possibleInvestments,
    4: data?.requiredInvestments,
    5: data?.requiredInvestments,
  };
  return (
    <div className="card">
      <Link onClick={onSearch} to={"/offer/" + uid}>
        <img src={getImageURL({ path: cover, size: "mini", type: "product/cover" })} alt="English 1st" />
      </Link>
      {userId && user?.id != userId && dataStatus &&
        <div className="card-fav d-flex align-items-center">
          <BtnFav favo={favo} favId={id} onFav={onFav} className='fs-18' />
          {/* <button type='button' className='btn-secondary fs-14 p-1 p-lg-2 ms-2'>
                <ImPencil />
              </button> */}
        </div>
      }
      <div className="card-cap">
        <Link onClick={onSearch} to={"/offer/" + uid} className="card-cap-title">
          <h5>{title}</h5>
          {categoryId == 2 ?
            <p className='fw-light'>Стоимость {parseInt(priceMap[categoryId]).toLocaleString('ru-RU')}&nbsp;Р</p>
            :
            <p className='fw-light'>Инвестиции от {parseInt(priceMap[categoryId]).toLocaleString('ru-RU')}&nbsp;Р</p>
          }
        </Link>

      </div>
    </div>
  )
}

export default AdvCard