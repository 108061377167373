import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import SecFavorites from '../components/SecFavorites'
import useIsMobile from '../hooks/isMobile'
import AccountLayout from '../layouts/AccountLayout'
import AccountMenu from '../pages/account/AccountMenu'
import AddOffer from '../pages/account/AddOffer'
import Callback from '../pages/account/Callback'
import CallbackChat from '../pages/account/CallbackChat'
import EmailVerification from '../pages/account/EmailVerification'
import Feedback from '../pages/account/Feedback'
import Finance from '../pages/account/Finance'
import LeaveFeedback from '../pages/account/LeaveFeedback'
import Messages from '../pages/account/Messages'
import MessagesDialogue from '../pages/account/MessagesDialogue'
import Offers from '../pages/account/Offers'
import PhoneVerification from '../pages/account/PhoneVerification'
import Profile from '../pages/account/Profile'
import PurchaseHistory from '../pages/account/PurchaseHistory'
import SalesHistory from '../pages/account/SalesHistory'
import Settings from '../pages/account/Settings'
import Info from '../pages/account/Info'
import Favorites from '../pages/account/Favorites'
import Partners from '../pages/account/Partners'
import { useSelector } from 'react-redux'
import Activate from '../pages/auth/Activate'
import Advertising from '../pages/account/Advertising'
import NotFound from '../pages/NotFound'

const AccountRouter = () => {
  const isMobileLG = useIsMobile('991px')
  const dataStatus = useSelector((state) => state?.auth?.user?.data?.status);
  return (
    <Routes>
      <Route path="/" element={<AccountLayout isMobile={isMobileLG} />}>
        {
          (isMobileLG)
            ? <Route index element={<AccountMenu />} />
            : <Route index element={<Navigate to="profile" replace={true} />} />
        }
        <Route path="profile" element={<Profile />} />
        <Route path="profile/phone" element={<PhoneVerification />} />
        <Route path="profile/email" element={<EmailVerification />} />
        <Route path="partners" element={<Partners />} />

        <Route path="info" element={<Info />} />
        {dataStatus && <>
          <Route path="offers" element={<Offers />} />
          <Route path="offers/add" element={<AddOffer />} />
          <Route path="offers/edit/:id" element={<AddOffer />} />
          <Route path="finance" element={<Finance />} />
          <Route path="messages" element={<Messages />} />
          <Route path="messages/:dialogId" element={<MessagesDialogue />} />
          <Route path="advertising" element={<Advertising />} />
          <Route path="favs" element={<Favorites />} />
        </>}
        <Route path="settings" element={<Settings />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default AccountRouter