import React from 'react';
import PhotoPlacement from "../assets/imgs/photo-placement.jpg";
import { getImageURL } from '../helpers/all';
import { Link, useNavigate } from 'react-router-dom';

const LeftCard = ({ title, link, media, desc }) => {

  // const image = getImageURL()
  return (
    <Link to={link} target='_blank' className="left-card">
      <img
        src={
          getImageURL({
            path: media,
            type: "banner",
            size: "",
          })
        }
        alt="userphoto"
      />
      <h6 className='mb-1'>{title}</h6>
      <p className='fs-09 mb-2'>{desc}</p>
    </Link>
  )
}

export default LeftCard