import React, { useCallback } from 'react';
import PhotoPlacement from "../assets/imgs/photo-placement.jpg";
import { declOfNum, getImageURL } from '../helpers/all';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { deleteUserProduct } from '../services/product';
import { NotificationManager } from 'react-notifications';

const AccountOffer = ({ id, title, cover, status, onStatus, category, uid, categoryId, data, incoming, blockedComment, end, onDelete }) => {
  const today = moment();
  const diffInDays = moment(end).diff(today, 'days');
  const declension = declOfNum(diffInDays, ["день", "дня", "дней"]);
  const navigate = useNavigate()

  return (
    <div className="account-offer">
      <Link to={"/offer/" + uid}>
        <img
          src={cover ? getImageURL({ path: cover, size: "mini", type: "product/cover" }) : PhotoPlacement}
          alt="userphoto"
          className='account-offer-img'
        />
      </Link>
      <Link to={"/offer/" + uid} className='account-offer-info'>
        <h6 className='color-1 mb-1'>{title}</h6>
        <p className='fs-08 dark-gray mb-2'>{category?.title}</p>
        <p className='fw-medium fs-09 mb-2'>{
          (categoryId == 1 && data?.startingInvestmentsFrom && parseInt(data?.startingInvestmentsFrom).toLocaleString('ru-RU')) ||
          (categoryId == 2 && data?.businessCost && parseInt(data?.businessCost).toLocaleString('ru-RU')) ||
          (categoryId == 3 && data?.possibleInvestments && parseInt(data?.possibleInvestments).toLocaleString('ru-RU')) ||
          (categoryId == 4 && data?.requiredInvestments && parseInt(data?.requiredInvestments).toLocaleString('ru-RU')) ||
          (categoryId == 5 && data?.requiredInvestments && parseInt(data?.requiredInvestments).toLocaleString('ru-RU'))
        } &nbsp;Р</p>
        {!incoming ? end && <p>Осталось: <strong className='color-1 fs-09'>{diffInDays} {declension} - до {moment(end).format('DD MMMM')}</strong></p>
          :
          status == -1 ?
            <strong className='color-1 fs-09'><span className='red'>Заблокировано.</span> <u>Узнать причину</u></strong>
            :
            status == 2 ?
              <strong className='color-1 fs-09'>На модерации</strong>
              :
              blockedComment != null ?
                <strong className='color-1 fs-09'><span className='red'>Отклонено.</span> <u>Узнать причину</u></strong>
                :
                <strong className='color-1 fs-09'>Архив</strong>
        }
      </Link>
      <div className='account-offer-btns'>
        <div className='line'>
          <button type='button' className='btn-primary' onClick={() => navigate(`edit/${id}`)}>Редактировать</button>
          {status != -1 && (status == 2 || blockedComment == null) && <button type='button' className='btn-gray' onClick={() => onStatus({ id: id, status: status === 1 ? 0 : status === 2 ? 0 : 2 })}>
            {status === 1 ? "Снять с публикации" : status === 2 ? "Отменить" : "Опубликовать"}
          </button>}
        </div>
        <div className='line'>
          <button
            type='button'
            className='btn-primary '
            onClick={() => onDelete(id)}
          >Удалить объявление</button>
        </div>


      </div>
    </div>
  )
}

export default AccountOffer