import { React } from 'react';
import { useSwiper } from 'swiper/react';
import { MdNavigateNext } from "react-icons/md";

const SwiperNextButton = () => {
    const swiper = useSwiper();
    return (
        <button onClick={() => swiper.slideNext()} className='swiper-next'>
            <MdNavigateNext/>
        </button>
    )
}

export default SwiperNextButton