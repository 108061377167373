import React, { useEffect, useState } from "react";
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTopButton from "../components/utils/ScrollToTopButton";
import { getCategoryList } from "../services/category";

const AppLayout = () => {
  const [categories, setCategories] = useState({ items: [], data: [], loading: true });
  const location = useLocation();
  useEffect(() => {

    getCategoryList()
      .then((res) => {
        setCategories(prev => ({ ...prev, items: res.sort((a, b) => a.commission - b.commission), loading: false }));
      })
      .catch(() => setCategories((prev) => ({ ...prev, loading: false })));


  }, []);
  useEffect(() => {
    const isGecko = navigator.userAgent.indexOf("Gecko/") > -1;
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isGecko || isSafari) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    }
  }, [location]);
  return (
    <div>
      <ScrollRestoration />
      <Header categories={categories} />
      <ScrollToTopButton />
      <Outlet />
      <Footer categories={categories} />
    </div>
  );
};

export default AppLayout;
