import React, { useCallback, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import { authNewKeyActivate, authPasswordRecovery } from "../../services/auth";
import Input from "../../components/utils/Input";
import { Timer } from "../../helpers/all";

const Recovery = () => {
  const navigate = useNavigate()
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
    reset,
    register,
  } = useForm({
    mode: "all", reValidateMode: "onSubmit", defaultValues: {
      step: 1,
    },
  });
  const [endTimer, setEndTimer] = useState(false);

  const data = useWatch({ control });


  const onSubmit = useCallback((data) => {
    authPasswordRecovery(data)
      .then(() => {
        if (data.step == 1 || data.step == 3) {
          NotificationManager.success(
            data.step == 1
              ? "Код подтверждения отправлен"
              : data.step == 3 && "Пароль успешно изменен"
          );
          if (data.step == 3) {
            navigate("/login/")
          }
        }
        reset({ ...data, step: data.step + 1 });
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error === "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
      });
  }, []);
  const getKey = useCallback(() => {
    setEndTimer(false)
    authNewKeyActivate()
      .then(() => {
        NotificationManager.success("Код подтверждения отправлен повторно");

        setLoading(false);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error === "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        )
        setLoading(false);
      });
  }, []);

  return (
    <main>
      <Container>
        <section className="py-5 mb-6">
          <h1 className="large text-center mb-4">Восстановление пароля</h1>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={6}>
              <form className='mini' onSubmit={handleSubmit(onSubmit)}>
                {!data.step || data.step === 1 ? (
                  <>
                    <p className='mb-5'>Введите адрес электронной почты, которую вы вводили при регистрации</p>

                    <Row className='g-3 g-md-4'>
                      <Col md={8}>
                        <Input
                          type="email"
                          label="E-mail"
                          name="email"
                          placeholder='user@mail.com'
                          errors={errors}
                          register={register}
                          validation={{
                            required: "Введите email",
                            maxLength: {
                              value: 250,
                              message: "Максимально 250 символов",
                            },
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: "Неверный формат Email",
                            },
                          }}
                        />
                      </Col>
                      <Col md={4}>
                        <button type='submit' className='btn-1 h-100 w-100' disabled={!data?.email || data?.email?.length < 4}>Восстановить</button>
                      </Col>
                    </Row>
                  </>
                ) : data.step === 2 ? (
                  <>
                    <p className='mt-4 mb-3'>Введите код, отправленный на указанную электронную почту</p>
                    <Row className='g-3 g-md-4 mb-5'>
                      <Col md={12}>
                        <input className='code' type="number" placeholder='0000' onChange={(e) => setValue("key", e.target.value)} />
                      </Col>
                      <Col md={12}>
                        {endTimer ? (
                          <p className="text-center pointer " onClick={() => getKey()}>
                            <b><u> Отправить повторно код подтверждения</u></b>
                          </p>
                        ) : (
                          <p className="text-center">
                            Повторить отправку кода через <Timer onEnd={() => setEndTimer(true)} /> сек
                          </p>
                        )}
                      </Col>
                      <Col md={12}>
                        <button type='submit' className='btn-1 h-100 w-100' disabled={!data?.key || data?.key?.length < 4}>Отправить</button>
                      </Col>
                    </Row>
                  </>
                ) : data.step === 3 && (
                  <>
                    <Row>
                      <Col md={8}>
                        <Input
                          autoComplete="new-password"
                          type="password"
                          label="Новый пароль"
                          placeholder="Придумайте пароль"
                          name="password"
                          errors={errors}
                          register={register}
                          validation={{
                            required: "Введите пароль",
                            minLength: {
                              value: 6,
                              message: "Минимальное кол-во символов 6",
                            },
                            maxLength: {
                              value: 250,
                              message: "Максимальное кол-во символов 250",
                            },
                            pattern: {
                              value: /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z]{6,}/g,
                              message:
                                "Пароль должен содержать не менее 6 символов латинского алфавита и цифры",
                            },
                          }}
                        />
                      </Col>
                    </Row>
                    {/* <p className='rose fs-08'>Слишком короткий пароль</p>
                    <p className='fs-08'>Пароль должен содержать не менее 6 символов, и среди них должны быть заглавные и строчные буквы, цифры, специальные символы (т. е. *, %, &,!)</p> */}

                    <Row className='mt-4'>
                      <Col md={8}>
                        <Input
                          autoComplete="new-password"
                          className="mb-2"
                          type="password"
                          label="Подтверждение пароля"
                          placeholder="Повторите пароль"
                          name="passwordConfirm"
                          errors={errors}
                          register={register}
                          validation={{
                            required: "Введите повторный пароль",
                            minLength: {
                              value: 6,
                              message: "Минимальное кол-во символов 6",
                            },
                            maxLength: {
                              value: 250,
                              message: "Максимальное кол-во символов 250",
                            },
                            pattern: {
                              value: /(?=.*[0-9])(?=.*[a-z])[0-9a-zA-Z]{6,}/g,
                              message:
                                "Пароль должен содержать не менее 6 символов латинского алфавита и цифры",
                            },
                          }}
                        />
                      </Col>
                    </Row>

                    <button type='submit' className='btn-1 mt-4 mx-auto' disabled={!isValid}>Сохранить</button>
                  </>
                )}
              </form>
            </Col>
          </Row>
        </section>
      </Container>
    </main>
  );
};

export default Recovery;
