import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from 'react-router-dom';
import Meta from "../../components/Meta";
import Chat from "../../components/chat/Chat";
import ReturnIcon from '../../components/svg/ReturnIcon';
import Loader from "../../components/utils/Loader";
import ReturnTitle from '../../components/utils/ReturnTitle';
import socket from "../../config/socket";
import {
  createMessage,
  createMessageGeneral,
  getDialogs,
  getMessages,
  getMessagesGeneral
} from "../../services/message";
import DialogPreview from './DialogPreview';
import { updateNotification } from "../../store/reducers/notificationSlice";


const Messages = () => {
  const userId = useSelector(state => state.auth?.user?.id);


  const [dialogs, setDialogs] = useState({
    loading: true,
    items: [],
  });

  const dispatch = useDispatch();
  const onLoadDialogs = () => {
    dispatch(updateNotification({ message: -1 }))
    getDialogs()
      .then((res) =>
        setDialogs((prev) => ({
          ...prev,
          loading: false,
          items: res.dialogs,
          count: res.countOnline,
        }))
      )
      .catch(() => setDialogs((prev) => ({ ...prev, loading: false })));
  };
  useEffect(() => {
    onLoadDialogs();
  }, []);


  if (dialogs.loading) {
    return <Loader full />;
  }
  return (
    <section className='sec-messages'>
      <Meta title="Сообщения" />
      <ReturnTitle link={'/account'} title={'Чаты с партнерами'} />
      <h4 className="d-none d-lg-block mb-4">Чаты с партнерами</h4>

      <ul className="sec-messages-list">
        {dialogs?.items?.length > 0 ? (
          dialogs.items.map((dialog) => (
            <li>
              <DialogPreview {...dialog} userId={userId} key={dialog.id} />
            </li>
          ))) : (
          <li className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
            В данный момент нет диалогов
          </li>
        )
        }
      </ul>


    </section>
  );
};

export default Messages;