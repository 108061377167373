import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import NavBreadcrumbs from '../components/NavBreadcrumbs';
import BlogCard from '../components/BlogCard'
import { getArticles } from '../services/article';
import Loader from '../components/utils/Loader';
import Meta from '../components/Meta';
import { MdCached, MdArrowForwardIos } from "react-icons/md";
import SecPartners from '../components/SecPartners';
import NavPagination from '../components/NavPagination';
import { declOfNum } from '../helpers/all';
import { getPartner } from '../services/partner';

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
    getPage(sort, page.selected + 1);
  };
  const [sort, setSort] = useState();
  const [articles, setArticles] = useState({
    loading: true,
    items: [],
  });
  const [partner, setPartner] = useState({
    loading: true,
    items: [],
  });



  const getPage = useCallback((newSort, page) => {
    // Определяем целевую страницу и статус
    const targetPage = page || currentPage; // Используем переданную страницу или текущую
    let targetSort = sort; // Используем переданный статус или текущий
    if (newSort || newSort == 0) {
      setSort(newSort);
      targetSort = newSort;
      setCurrentPage(page);
    }
    // Запрос данных с новыми параметрами
    getArticles({ sort: targetSort, page: targetPage, size: 24 })
      .then((res) => {
        setArticles((prev) => ({
          prev,
          loading: false,
          ...res,
        }))
        setCurrentPage(res.pagination.currentPage)
      })
      .catch(() => setArticles((prev) => ({ ...prev, loading: false })));

  }, [currentPage, sort]);

  useEffect(() => {
    getPage();
    getPartner()
      .then(
        (res) =>
          res &&
          setPartner((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .catch(() => setPartner((prev) => ({ ...prev, loading: false })));
  }, []);

  const declension = declOfNum(articles?.pagination?.totalItems, ['предложение', 'предложения', 'предложений']);
  if (articles.loading) {
    return <Loader full />;
  }
  return (
    <main>
      <Meta title="Бизнес новости, обучающие бизнес статьи сайта Бизнес My Life (БМЛ)" description="У нас бизнес новости и стать на тему - какой бизнес открыть, стартапы 2024, как выбрать свой бизнес, IT бизнес, куда вложить деньги, инвестиции, бизнес аналитика, т инвестиции" />
      <Container>
        {/* <NavBreadcrumbs title="Новости" /> */}

        <section className='py-4 py-sm-5'>
          <h1 className='h2'>Новости и статьи</h1>
          <nav className="catalog-nav">
            <NavPagination totalPages={articles?.pagination?.totalPages} onPageChange={onPageChange} currentPage={currentPage} />
            {articles?.pagination?.totalItems <= 36 ? <p>Показано {articles?.pagination?.totalItems} {declension} из {articles?.pagination?.totalItems}</p>
              : <p>Показано 24 предложений из {articles?.pagination?.totalItems}</p>}
            <select defaultValue={false} value={sort} onChange={(e) => getPage(e.target.value, 1)}>
              <option value="false" disabled>Сортировать:</option>
              <option value="new">по дате публикации: новые</option>
              <option value="old">по дате публикации: ранние</option>
            </select>
          </nav>

          <Row xs={1} sm={2} md={3} lg={4} className='g-4'>
            {articles?.items?.length > 0 && articles.items.map((item, index) => (
              <Col key={index}>
                <BlogCard {...item} />
              </Col>
            ))}
          </Row>

          <nav className="catalog-nav">
            <NavPagination totalPages={articles?.pagination?.totalPages} onPageChange={onPageChange} currentPage={currentPage} />
            {articles?.pagination?.totalItems <= 36 ? <p>Показано {articles?.pagination?.totalItems} {declension} из {articles?.pagination?.totalItems}</p>
              : <p>Показано 24 предложений из {articles?.pagination?.totalItems}</p>}
            <select defaultValue={false} value={sort} onChange={(e) => getPage(e.target.value, 1)}>
              <option value="false" disabled>Сортировать:</option>
              <option value="new">по дате публикации: новые</option>
              <option value="old">по дате публикации: ранние</option>
            </select>
          </nav>
        </section>

        {/* <section className='page-blog mb-3 mb-sm-4 mb-md-5'>
          <h1 className='mb-3 mb-sm-4 mb-md-5'>Новости биржи игровых ценностей</h1>
          <div className="mb-5">
            <ul className='list-unstyled row row-cols-2 row-cols-lg-3 gx-2 gx-sm-4 gy-4 gy-sm-5'>
              {articles?.items?.length > 0 && articles.items.map((item) => (
                <li>
                  <BlogCard2 {...item} />
                </li>
              ))}
            </ul>
          </div>
          <div className="list-wrapping-bottom">
            <NavPagination totalPages={articles?.pagination?.totalPages} onPageChange={onPageChange} />
          </div>
        </section> */}
      </Container>

      <SecPartners partner={partner} />
    </main>
  );
};

export default Blog;