import React, { useCallback, useEffect, useState } from "react";
import { Badge, Button, Col, Container, Dropdown, Modal, Offcanvas, Row } from "react-bootstrap";
import { ImStarEmpty, ImStarFull } from "react-icons/im";
import { MdArrowDropDown, MdLogin, MdLogout, MdMenu, MdOutlinePerson, MdOutlineSearch } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Logo from "../assets/imgs/logo-bml.svg";
import { logout } from "../services/auth";
import { getCategoryList } from "../services/category";
import SecFavorites from "./SecFavorites";
import { getImageURL } from "../helpers/all";
import { getSearch } from "../services/search";
import useDebounce from "../hooks/useDebounce";
import AdvCard from "./AdvCard";
import { toggleFavorite } from "../services/favorite";
import useIsMobile from "../hooks/isMobile";

const Header = ({ categories }) => {
  const { uid } = useParams();
  const isMobile = useIsMobile('991px')
  const { isAuth, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const userId = useSelector(state => state.auth?.user?.id);
  const unreadCount = useSelector((state) => state.notification.message);
  const favorites = useSelector((state) => state.favorite.items);
  const dataStatus = useSelector((state) => state?.auth?.user?.data?.status);

  const [showAdvice, setShowAdvice] = useState(false);
  const handleCloseAdvice = () => setShowAdvice(false);
  const handleShowAdvice = () => setShowAdvice(true);

  const [showFav, setShowFav] = useState(false);
  const handleCloseFav = () => setShowFav(false);
  const handleShowFav = () => setShowFav(true);

  const [showSearch, setShowSearch] = useState(false);
  const handleCloseSearch = () => { setShowSearch(false), setSearchTerm("") };
  const handleShowSearch = () => setShowSearch(true);

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const [category, setCategory] = useState({ items: [], loading: true });;
  useEffect(() => {
    if (!searchTerm) {
      handleCloseSearch();
    }
  }, [searchTerm]);
  const [showAlert, setShowAlert] = useState(false);
  const handleShowAlert = () => {
    handleCloseSearch();
    setShowAlert(true);
  };
  const handleCloseAlert = () => {
    setShowAlert(false);
  };
  const onSearch = useCallback((e) => {
    if (searchTerm) {
      setCategory((prev) => ({ ...prev, loading: true }));
      getSearch(searchTerm)
        .then((res) => {
          setCategory(prev => ({
            ...prev,
            items: res,
            loading: false
          }));
          handleShowSearch();
        })
        .catch(() => setCategory((prev) => ({ ...prev, loading: false })));

    }
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const onFav = useCallback((e) => {
    dispatch(toggleFavorite({ productId: e, }));
  }, []);
  return (
    <>
      <header>
        <Container className="top">
          <Link onClick={handleCloseSearch} to="/">
            <img src={Logo} alt="БИЗНЕС MY LIFE" className="logo" />
          </Link>
          <div className="d-flex align-items-center">
            <form action="" >
              <input
                value={searchTerm}
                type="search"
                placeholder="Поиск по сайту"
                onChange={(event) => {
                  handleSearchChange(event);
                }}
              />
              <button type="button" onClick={() => onSearch()}>
                <MdOutlineSearch />
              </button>
            </form>

            {/* <div className="d-none d-md-block ms-lg-4 ms-xxl-5">
            <button type="button" className="color-2 text-decoration-underline fs-09 mb-2">Заказать звонок</button>
            <a href="tel:88432565656" className="d-block fw-bold">8 (843) 256 56 56</a>
          </div> */}
            {dataStatus ?
              <Link onClick={handleCloseSearch} to={isAuth ? "/account/favs" : "/login"} className="position-relative d-flex align-items-center ms-2 ms-lg-3 ms-xxl-4">
                {(favorites && favorites.length > 0) ? <ImStarFull className="fs-20" /> : <ImStarEmpty className="fs-20" />}
                {/* <Badge bg="secondary">2</Badge> */}
              </Link>
              :
              <div onClick={handleShowAlert} className="position-relative d-flex align-items-center ms-2 ms-lg-3 ms-xxl-4 cursor">
                <ImStarEmpty className="fs-20" />
              </div>
            }
          </div>
          <div className="d-flex align-items-center ms-3 ms-sm-4">
            {
              isAuth
                ? (
                  <>
                    <Link onClick={handleCloseSearch} to="/account" className="d-flex align-items-center">
                      <span className="d-none d-xl-inline">{user.firstName}</span>
                      <div className="img">
                        <img src={getImageURL({ path: user.media, size: "mini", type: "user" })} alt="" />
                        {unreadCount && unreadCount > 0 ? <Badge bg="secondary">{parseInt(unreadCount)}</Badge> : ""}
                      </div>
                      {/* <MdOutlinePerson className="fs-19 ms-1" /> */}
                    </Link>

                  </>
                ) : (
                  <Link onClick={handleCloseSearch} to="/login" className="d-flex align-items-center">
                    <span className="d-none d-xl-inline">Войти</span>
                    <MdLogin className="fs-17 ms-2" />
                  </Link>
                )
            }
            {/* 
            <Link to="/account" className="d-flex align-items-center ms-4">
              <span className="d-none d-lg-inline">Личный кабинет</span>
              <MdLogin className="fs-13 ms-2"/>
            </Link> */}
          </div>
        </Container>

        <nav>
          <Container className="h-100" onClick={handleCloseSearch}>
            {isMobile ?
              <>
                <form action="" className="ms-lg-4 ms-xxl-5">
                  <Dropdown>
                    <Dropdown.Toggle variant="none">
                      <MdMenu className="lg-svg" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu as="div" align="end">
                      {categories?.items?.length > 0 && categories?.items?.map(category =>
                        <Dropdown.Item as="div" key={category.id}>
                          <Link className={(uid == category.uid) ? "active" : ""} to={'/catalog/' + category.uid}>{category.title}</Link>
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item as="div">
                        <Link to='/blog'>Новости</Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <Link to='/contacts'>Контакты</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <input
                    value={searchTerm}
                    type="search"
                    placeholder="Поиск по сайту"
                    onChange={(event) => {
                      handleSearchChange(event);
                    }}
                  />
                  <button type="button" onClick={() => onSearch()}>
                    <MdOutlineSearch />
                  </button>
                </form>
              </>
              :
              <ul>
                {categories?.items?.length > 0 && categories?.items?.map(category =>
                  <li key={category.id}>
                    <Link className={(uid == category.uid) ? "active" : ""} to={'/catalog/' + category.uid}>{category.title}</Link>
                  </li>
                )}
                <li>
                  <Dropdown>
                    <Dropdown.Toggle variant="none">
                      <span>Информация</span>
                      <MdArrowDropDown className="fs-12 ms-1" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu as="div" align="end">
                      <Dropdown.Item as="div">
                        <Link to='/blog'>Новости</Link>
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <Link to='/contacts'>Контакты</Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            }
          </Container>
        </nav>
      </header >

      <Modal show={showAdvice} onHide={handleCloseAdvice} size={"lg"} centered>
        <Modal.Header closeButton>
          <img
            src="/imgs/icons/warning.png"
            alt="warning"
            className="warning"
          />
        </Modal.Header>
        <Modal.Body>
          <h2 className="mb-0">Пожалуйста, войдите или зарегистрируйтесь</h2>
          <p>
            Добавлять игры в избранное могут только авторизованные пользователи.
          </p>
          <Link to="login" className="btn-3 mt-4">
            Войти
          </Link>
        </Modal.Body>
      </Modal>

      <Modal show={showAlert} onHide={handleCloseAlert} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h4 className="mb-4">Размещение объявлений и полный доступ к сайту будет доступен после заполнения информации профиля</h4>

          <div>
            <div className="d-flex justify-content-between">
              <Link className="btn-1 mr-3" to="/account/settings" onClick={handleCloseAlert}>Заполнить профиль</Link>
              <Button onClick={handleCloseAlert} className="mr-3">Закрыть</Button>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Offcanvas
        show={showFav}
        onHide={handleCloseFav}
        placement="top"
        className="fav-modal"
      >
        <Container className="px-0">
          <Offcanvas.Body>
            <SecFavorites />
          </Offcanvas.Body>
        </Container>
      </Offcanvas>

      <Offcanvas
        show={showSearch}
        onHide={handleCloseSearch}
        onClick={handleCloseSearch}
        onEscapeKeyDown={handleCloseSearch}
        placement="top"
        autoFocus={false}
        className="fav-modal"
        scroll={true}
      >
        <Offcanvas.Body>
          <Container onClick={(e) => e.stopPropagation()}>
            <Row className="justify-content-center g-2 g-sm-3 g-xl-4">

              {category?.items && category?.items?.length > 0 ? category?.items?.map(item =>
                <Col xs={6} md={4} xl={3}><AdvCard {...item} onSearch={handleCloseSearch} onFav={onFav} /></Col>
              )
                :
                <Col xs={12} className='align-item-center text-center mb-4'>
                  <h3 className='text-center'>Нет обьявлений</h3>
                </Col>

              }
            </Row>
          </Container>
        </Offcanvas.Body>
      </Offcanvas >
    </>
  );
};

export default Header;
