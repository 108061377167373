import React from 'react'
import { Container } from "react-bootstrap";
import dataPartners from '../data/partners.json';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import { getImageURL } from '../helpers/all';
import { Link } from 'react-router-dom';

const SecPartners = ({ partner }) => {
    return (
        <section className="sec-partners">
            <Container>
                <h2>Наши партнёры</h2>
                <Swiper
                    modules={[Autoplay]}
                    loop={true}
                    spaceBetween={12}
                    slidesPerView={2}
                    pagination={false}
                    autoplay={{
                        delay: 7000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        576: {
                            slidesPerView: 3,
                            spaceBetween: 12
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 16
                        },
                        992: {
                            slidesPerView: 5,
                            spaceBetween: 20
                        },
                        1200: {
                            slidesPerView: 6,
                            spaceBetween: 20
                        },
                        1400: {
                            slidesPerView: 6,
                            spaceBetween: 30
                        }
                    }}
                >
                    {partner?.items.length > 0 && partner?.items.map(obj =>
                        <SwiperSlide key={obj.id}>
                            <Link to={obj?.title} target="_blank"><img src={getImageURL({ path: obj.media, size: "", type: "partner" })} alt={obj.title} /></Link>
                        </SwiperSlide>
                    )
                    }

                </Swiper>
            </Container>
        </section>
    )
}

export default SecPartners