import React, { useCallback, useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
import AccountOffer from '../../components/AccountOffer';
import Loader from '../../components/utils/Loader';
import ReturnTitle from '../../components/utils/ReturnTitle';
import { deleteUserProduct, editUserProduct, getUserProducts } from '../../services/product';
import { NotificationManager } from 'react-notifications';
import NavPagination from '../../components/NavPagination';

const Offers = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const onPageChange = (page) => {
    setCurrentPage(page.selected + 1);
  };
  const [products, setProducts] = useState({
    loading: true,
    items: [],
  });
  const [status, setStatus] = useState(1);
  const [categoryId, setCategoryId] = useState("all");
  useEffect(() => {
    getPage();
  }, [currentPage, status, categoryId]);
  const getPage = () => {
    setProducts((prev) => ({ ...prev, loading: true }));
    if (categoryId != "all") {
      getUserProducts({
        categoryId: categoryId, page: currentPage, status: status == 1 && status, size: 10
      })
        .then((res) => {
          setProducts((prev) => ({
            ...prev,
            loading: false,
            items: res.products.items,
            pagination: res.products.pagination,
            categories: res.categories.sort((a, b) => a.commission - b.commission),
            countAds: res.countAds,
            countArchive: res.countArchive,

          }))
        })
        .catch(() => setProducts((prev) => ({ ...prev, loading: false })));
    }
    else {
      getUserProducts({
        page: currentPage, status: status == 1 && status, size: 10
      })
        .then((res) => {
          setProducts((prev) => ({
            ...prev,
            loading: false,
            items: res.products.items,
            pagination: res.products.pagination,
            categories: res.categories.sort((a, b) => a.commission - b.commission),
            countAds: res.countAds,
            countArchive: res.countArchive,

          }))
        })
        .catch(() => setProducts((prev) => ({ ...prev, loading: false })));
    }
  }
  const onStatus = (data) => {
    editUserProduct(data)
      .then(() => {
        NotificationManager.success("Объявление обновлёно");

        getPage();
      })
      .catch(
        (err) => {
          err &&
            NotificationManager.error(
              err?.response?.data?.error ?? "Неизвестная ошибка при отправке"
            )
        }
      );
  }
  const onDelete = useCallback((id) => {
    deleteUserProduct(id)
      .then(() => {
        NotificationManager.success("Объявление удалёно");
        getPage();
      })
      .catch(
        (err) => {
          err &&
            NotificationManager.error(
              err?.response?.data?.error ?? "Неизвестная ошибка при отправке"
            )
        }
      );
  }, []);
  return (
    <section>
      <ReturnTitle link={'/account'} title={'Мои объявления'} />

      <Tab.Container defaultActiveKey="first">
        <div className="d-md-flex flex-md-row-reverse align-items-center mb-4">
          <Link to='add' className='btn-add-offer ms-md-auto my-4 my-md-0'>Новое объявление</Link>

          {/* <div className="d-flex align-items-center">
            <button type='button' eventKey="first" className='fs-12 me-4'>
              Объявления <span className='gray'>3</span>
            </button>
            <button type='button' eventKey="second" className='fs-12'>
              
            </button>
          </div> */}

          <Nav variant="tabs" defaultActiveKey="first" onSelect={(selectedKey) => setStatus(selectedKey === 'first' ? 1 : 0)}>
            <Nav.Item>
              <Nav.Link eventKey="first">
                Объявления <span className='gray'>{products?.countAds}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
                Архив <span className='gray'>{products?.countArchive}</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content>
          {products?.loading && <Loader full />}
          <Tab.Pane eventKey="first">
            <select defaultValue={"all"} onChange={(e) => { setCategoryId(e.target.value), setCurrentPage(1) }}>
              <option value="all">Все</option>
              {products?.categories && products?.categories?.map(category =>
                <option value={category?.id}>{category?.title}</option>
              )}
            </select>
            {
              products?.items.length > 0 ?
                <ul className='mt-4 list-unstyled'>

                  {
                    products?.items.map((item) => (
                      <li className='mb-4'>
                        <AccountOffer {...item} onStatus={onStatus} onDelete={onDelete} />
                      </li>))
                  }
                </ul>
                :
                <div className="mt-4 fw-medium">
                  Нет объявлений
                </div>
            }
            <NavPagination totalPages={products.pagination?.totalPages} onPageChange={onPageChange} currentPage={currentPage} />
          </Tab.Pane>

          <Tab.Pane eventKey="second">
            <select defaultValue={"all"} onChange={(e) => { setCategoryId(e.target.value), setCurrentPage(1) }}>
              <option value="all">Все</option>
              {products?.categories && products?.categories?.map(category =>
                <option value={category?.id}>{category?.title}</option>
              )}
            </select>

            {
              products?.items.length > 0 ?
                <ul className='mt-4 list-unstyled'>

                  {
                    products?.items.map((item) => (
                      <li className='mb-4'>
                        <AccountOffer {...item} onStatus={onStatus} incoming={true} onDelete={onDelete} />
                      </li>))
                  }
                </ul>
                :
                <div className="mt-4 fw-medium">
                  Нет объявлений
                </div>
            }
            <NavPagination totalPages={products.pagination?.totalPages} onPageChange={onPageChange} currentPage={currentPage} />
          </Tab.Pane>
        </Tab.Content>


      </Tab.Container>

      {/* <div className='row'>
        <div className='col-12 col-xxl-11 col-xxxl-10'>
          
          <Meta title="Мои объявления" />
          <div className="d-flex align-items-start">
            <Link to='add' className='btn-add-offer me-3 me-md-4'>
              <Plus />
            </Link>
            <div className='flex-1'>
              <ul className='list-unstyled g-2 g-sm-4 row row-cols-sm-2 row-cols-md-3 row-cols-xxl-4'>
                <li>
                  <GameMiniCard />
                </li>
                <li><GameMiniCard /></li>
                <li><GameMiniCard /></li>
                <li><GameMiniCard /></li>
              </ul>
              <button type='button' className='d-flex flex-column align-items-center pale-blue fs-12 mx-auto mt-4 mb-4 mb-sm-5'>
                <span>Показать все</span>
                <FiChevronDown className='fs-13' />
              </button>
            </div>
          </div>
          {products?.pagination?.totalItems > 0 ?
            (
              <div className="list-wrapping">
                <div className="list-wrapping-top">
                  <ul className='line-2'>
                    <li className='descr'>Описание</li>
                    <li className='price'>Цена</li>
                    <li className='btns'></li>
                  </ul>
                </div>
                <div className="list-wrapping-main">
                  {products.loading ? (
                    <div className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
                      Загрузка лотов...
                    </div>
                  ) : (
                    <ul className='g-4 g-xl-0 row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-1'>
                      {products?.items.map((item) => (
                        <li>
                          <OfferLine2 {...item} />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="list-wrapping-bottom">
                  <NavPagination totalPages={products?.pagination?.totalPages} onPageChange={onPageChange} />
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center mt-4">
                <h3>
                  Нет объявлений
                </h3>
              </div>
            )}
        </div>
      </div> */}
    </section >
  );
};

export default Offers;